import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { isFacilitatorRoute } from '../redux/store';
import { connect } from 'react-redux';
import useConfig from '../hooks/use-config';

export default connect((state) => ({
  gameState: state.gameState,
}))(({ gameState, ...rest }) => (
  <BlackoutScreen isOpen={gameState?.isBlackoutScreenVisible} {...rest} />
));
export function BlackoutScreen({ isOpen }) {
  const { tFallback } = useConfig();
  if (isFacilitatorRoute()) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} style={modalDialogStyle}>
      <ModalBody style={modalStyle}>
        <div style={blackoutScreenContainerStyle}>
          <div style={pauseIconStyle}>
            <div style={stickStyle} />
            <div style={stickStyle} />
          </div>
          <div style={pauseTextStyle}>
            {tFallback('LABEL_PAUSED', 'Paused').toUpperCase()}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const modalStyle = {
  backgroundColor: 'black', // solid black background
  border: 'none',
  height: '100vh',
  color: 'white', // text color
  display: 'grid',
  placeItems: 'center', // centering items
};

const modalDialogStyle = {
  opacity: 0.85,
  margin: 0,
  maxWidth: '100%',
  width: '100%',
  height: '100%',
  zIndex: 10000, // high z-index
  position: 'fixed',
  top: 0,
  left: 0,
};

const blackoutScreenContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '40px',
};

const pauseIconStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
};

const stickStyle = {
  width: '15px',
  height: '60px',
  borderRadius: '20px',
  backgroundColor: 'white',
};

const pauseTextStyle = {
  fontSize: '80px',
};
