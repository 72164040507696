import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useDictionary } from '../context/dictionary-context'; // Import useDictionary hook

import Wrapper from '../components/Wrapper';
import Sidebar from '../components/Sidebar';
import Main from '../components/Main';
import Navbar from '../components/PlayerNavbar';
import Content from '../components/Content';
import Footer from '../components/Footer';
import PromptModal from '../components/PromptModal';
import Loader from '../components/Loader'; // Assuming you have a Loader component
import Tutorial from '../components/Tutorial';

import {
  getConversationState,
  getGameState,
  markChannelAsRead,
} from '../services/apiService';

import { getActiveMessageToPrompt } from '../redux/reducers/player/conversationStateReducer';
import useOnChangeVisibility from '../hooks/use-on-change-visibility';
import useConfig from '../hooks/use-config';
import { toastr } from 'react-redux-toastr';

const Player = (props) => {
  const { tFallback } = useConfig();
  const { loading, error } = useDictionary(); // Use the dictionary context to access loading state

  const { messageToPrompt } = props;

  const refreshData = () => {
    getGameState();
    getConversationState(); // Required onMount to determine if we need to prompt for new conversation when page loads
  };

  useEffect(refreshData, []);

  useEffect(() => {
    if (error) {
      toastr.error(error);
    }
  }, [error]);

  useOnChangeVisibility((visibility) => {
    if (visibility === 'visible') {
      refreshData();
    }
  });

  // Display loader if dictionary is still loading
  if (loading || !props.isLoaded || !props.playerContext) {
    return <Loader />;
  }

  const handleClickModalFooter = () => {
    if (messageToPrompt.sender === 'Facilitator') {
      markChannelAsRead('General', props.playerContext.companyName);
    }

    if (messageToPrompt.type === 'ai') {
      markChannelAsRead(messageToPrompt.channel);
    }

    props.history.push(`/conversations/${messageToPrompt.channel}`);
  };

  return (
    <React.Fragment>
      {
        // TODO: I think we instructional design should revisit conversation title since it is relied on more now in prompt and message subjects in conversation view.
        messageToPrompt !== null && (
          <PromptModal
            titleLabel={
              messageToPrompt.subject === 'Facilitator Message'
                ? tFallback('LABEL_FACILITATOR_MESSAGE', 'Facilitator Message')
                : messageToPrompt.subject
            }
            bodyLabel={
              messageToPrompt.sender === 'Facilitator'
                ? messageToPrompt.body.includes('<video') ||
                  messageToPrompt.body.includes('<iframe')
                  ? tFallback(
                      'LABEL_NEW_VIDEO_MESSAGE_CLICK_TO_PLAY',
                      'New video message, click view message to play!'
                    )
                  : messageToPrompt.body.includes('<div>') &&
                    messageToPrompt.body.toLowerCase().includes('survey')
                  ? tFallback(
                      'LABEL_NEW_SURVEY_MESSAGE',
                      'New survey available, click view message to get the link!'
                    )
                  : messageToPrompt.body
                      .replace(/<br \/>/g, '')
                      .replace(/<br>/g, '')
                : tFallback(
                    'LABEL_MEETING_ABOUT_TO_BEGIN',
                    'Your meeting is about to begin.'
                  )
            }
            actionButtonLabel={
              messageToPrompt.sender === 'Facilitator'
                ? tFallback('LABEL_VIEW_MESSAGES', 'View Messages')
                : tFallback('LABEL_GO_TO_MEETING', 'Go to Meeting')
            }
            onClickActionButton={handleClickModalFooter}
          />
        )
      }
      <Wrapper onkeydown={(e) => console.log('keying!', e)}>
        <Sidebar playerContext={props.playerContext} />
        <Main>
          <Navbar />
          <Content>{props.children}</Content>
          <Footer />
          <Tutorial />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default withRouter(
  connect((store) => ({
    isLoaded: store.gameState && store.gameState.isLoaded,
    playerContext: store.gameState?.playerContext,
    isTutorialActiveProjectsTab: store.player?.isTutorialActiveProjectsTab,
    messageToPrompt: store.conversations
      ? getActiveMessageToPrompt(store.conversations) || null
      : null,
    isTutorialActive: store.player?.isTutorialActive,
  }))(Player)
);
