import * as types from "../../redux-constants";

const reportTeamColors = [
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(255,99,132,1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(51,102,204,1)',
  'rgba(220,57,18,1)',
  'rgba(255,153,0,1)',
  'rgba(16,150,24,1)',
  'rgba(153,0,153,1)',
  'rgba(59,62,172,1)',
  'rgba(0,153,198,1)',
  'rgba(221,68,119,1)',
  'rgba(102,170,0,1)',
  'rgba(184,46,46,1)',
  'rgba(49,99,149,1)',];

const reportBorderColors = [
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(255,99,132,1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(51,102,204,1)',
  'rgba(220,57,18,1)',
  'rgba(255,153,0,1)',
  'rgba(16,150,24,1)',
  'rgba(153,0,153,1)',
  'rgba(59,62,172,1)',
  'rgba(0,153,198,1)',
  'rgba(221,68,119,1)',
  'rgba(102,170,0,1)',
  'rgba(184,46,46,1)',
  'rgba(49,99,149,1)',
  'rgba(153,68,153,1)',
  'rgba(34,170,153,1)',
  'rgba(170,170,17,1)',
  'rgba(102,51,204,1)',
  'rgba(230,115,0,1)',
  'rgba(139,7,7,1)',
  'rgba(50,146,98,1)',
  'rgba(85,116,166,1)',
  'rgba(59,62,172,1)'
];

const reportBackgroundColors = [
  'rgba(54, 162, 235, 0.3)',
  'rgba(255, 206, 86, 0.3)',
  'rgba(75, 192, 192, 0.3)',
  'rgba(255, 99, 132, 0.3)',
  'rgba(153, 102, 255, 0.3)',
  'rgba(255, 159, 64, 0.3)',
  'rgba(51,102,204,0.3)',
  'rgba(220,57,18,0.3)',
  'rgba(255,153,0,0.3)',
  'rgba(16,150,24,0.3)',
  'rgba(153,0,153,0.3)',
  'rgba(59,62,172,0.3)',
  'rgba(0,153,198,0.3)',
  'rgba(221,68,119,0.3)',
  'rgba(102,170,0,0.3)',
  'rgba(184,46,46,0.3)',
  'rgba(49,99,149,0.3)',
  'rgba(153,68,153,0.3)',
  'rgba(34,170,153,0.3)',
  'rgba(170,170,17,0.3)',
  'rgba(102,51,204,0.3)',
  'rgba(230,115,0,0.3)',
  'rgba(139,7,7,0.3)',
  'rgba(50,146,98,0.3)',
  'rgba(85,116,166,0.3)',
  'rgba(59,62,172,0.3)'
];

const themes = {
  highVisibility: {
    primary: "#47bac1",
    secondary: "#5c4058",
    tertiary: "#5fc27e",
    success: "#5fc27e",
    info: "#5b7dff",
    warning: "#fcc100",
    danger: "#f44455"
  },
  modern: {
    primary: "#47BAC1",
    secondary: "#5c4058",
    tertiary: "#5997eb",
    success: "#4caf50",
    info: "#47bac1",
    warning: "#ff9800",
    danger: "#e51c23",
    functional: "#3cb5e4",
    communication: "#fac133"
  }
};

const initialState = {
  currentTheme: themes.modern,
  themes: themes,
  reportTeamColors,
  reportBackgroundColors,
  reportBorderColors
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.THEME_TOGGLE:
      return {
        ...state,
        currentTheme: state.themes[actions.payload]
      };

    default:
      return state;
  }
}