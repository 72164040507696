import React, { useState } from 'react';
import {
  createSession,
  getFacilitatorState,
  getFacilitatorReportState,
  resetSession,
  rollbackRound,
} from '../services/facilitatorApiService';
import Navbar from './Navbar';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
} from 'reactstrap';
import _ from 'lodash';
import { RefreshCcw, Rewind } from 'react-feather';

import store from '../redux/store';
import { SET_FACILITATOR_SESSION_ID } from '../redux/redux-constants';
import PromptModal from './PromptModal';
import useConfig from '../hooks/use-config';

const FacilitatorNavbar = ({
  currentMonth,
  isMonthActive,
  handleToggleSidebar,
  allSessionIds,
  selectedSessionId,
}) => {
  const { tFallback } = useConfig();
  const [sessionInputValue, setSessionInputValue] = useState('');
  const [isSessionMenuOpen, toggleSessionMenuOpen] = useState(false);
  const [showConfirmRollback, setShowConfirmRollback] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);

  const handleChangeNewSessionId = (e) => {
    setSessionInputValue(e.target.value);
  };

  const handleCreateSession = () => {
    createSession(sessionInputValue);
    setSessionInputValue('');
    toggleSessionMenuOpen(false);
  };

  const handleResetSession = () => {
    resetSession();
  };

  const handleSelectSession = (sessionId) => {
    store.dispatch({
      type: SET_FACILITATOR_SESSION_ID,
      payload: sessionId,
    });

    getFacilitatorState(sessionId);
    getFacilitatorReportState(sessionId);
  };

  const handleRollbackRound = () => {
    rollbackRound();
  };

  return (
    <Navbar color="dark" onToggleSidebar={handleToggleSidebar}>
      {showConfirmRollback && (
        <PromptModal
          titleLabel={tFallback('LABEL_ROLL_BACK_ROUND', 'Rollback Round')}
          bodyLabel={
            <div>
              <p>
                {tFallback(
                  'LABEL_WARNING_THIS_WILL_IMPACT_ALL_SESSIONS',
                  'Warning: this will impact all sessions!'
                )}
              </p>
              <p>
                {isMonthActive
                  ? `${tFallback(
                      'LABEL_ROLL_BACK_TO_START_OF',
                      'Rollback to start of'
                    )} \n ${tFallback('MONTH', 'Month')} ${currentMonth}?`
                  : `${tFallback('LABEL_REOPEN', 'Reopen')} \n ${tFallback(
                      'MONTH',
                      'Month'
                    )} ${currentMonth}?`}
              </p>
            </div>
          }
          actionButtonLabel={tFallback('LABEL_OK_ALL_CAPS', 'OK')}
          onClickDismissButton={() => setShowConfirmRollback(false)}
          onClickActionButton={() => {
            setShowConfirmRollback(false);
            handleRollbackRound();
          }}
        />
      )}
      {showConfirmReset && (
        <PromptModal
          titleLabel={tFallback('LABEL_RESET_SESSION', 'Reset Session')}
          bodyLabel={
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: tFallback(
                    'LABEL_WARNING_THIS_ACTION_WILL_RESET_SIMULATION_TO_MONTH_1_AND_ERASE_COMPETITION_DATA',
                    'Warning: this action will reset simulation to month 1 and <strong>erase competition data.</strong>'
                  ),
                }}
              ></p>
              <p></p>
            </div>
          }
          confirmationText="RESET"
          actionButtonLabel="RESET"
          onClickDismissButton={() => setShowConfirmReset(false)}
          onClickActionButton={() => {
            setShowConfirmReset(false);
            handleResetSession();
          }}
        />
      )}
      <Nav className="ml-auto align-items-center" navbar>
        <NavItem style={{ width: '300px' }}>
          <Dropdown
            isOpen={isSessionMenuOpen}
            toggle={() =>
              !sessionInputValue && toggleSessionMenuOpen(!isSessionMenuOpen)
            }
          >
            <DropdownToggle caret color="light" className="w-100">
              {selectedSessionId ? (
                <span className="text-muted mr-4">
                  {tFallback('LABEL_CURRENT_SESSION', 'Current Session')}:{' '}
                  <span className="text-info">{selectedSessionId}</span>
                </span>
              ) : (
                tFallback('LABEL_SELECT_SESSION', 'Select Session')
              )}
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {selectedSessionId && (
                <React.Fragment>
                  <DropdownItem header>
                    {tFallback('LABEL_CURRENT_SESSION', 'Current Session')}:{' '}
                    <span className="text-info">{selectedSessionId}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                </React.Fragment>
              )}
              {((!selectedSessionId && allSessionIds.length > 0) ||
                (selectedSessionId && allSessionIds.length > 1)) && (
                <React.Fragment>
                  <DropdownItem header>
                    {tFallback('LABEL_SWITCH_SESSION', 'Switch Session')}
                  </DropdownItem>
                  {_.reject(
                    allSessionIds,
                    (sId) => sId === selectedSessionId
                  ).map((sId) => (
                    <DropdownItem
                      key={sId}
                      onClick={() => handleSelectSession(sId)}
                    >
                      {sId}
                    </DropdownItem>
                  ))}
                  <DropdownItem divider />
                </React.Fragment>
              )}
              <DropdownItem header className="m-3 p-3 border bg-light">
                {tFallback('LABEL_CREATE_NEW_SESSION', 'Create New Session')}
                <Input
                  className="my-3"
                  onChange={handleChangeNewSessionId}
                  placeholder={tFallback('LABEL_SESSION_NAME', 'Session Name')}
                  value={sessionInputValue}
                />
                <Button
                  color="info"
                  className="mr-2"
                  onClick={handleCreateSession}
                >
                  {tFallback('LABEL_SUBMIT', 'Submit')}
                </Button>
                <Button
                  color="dark"
                  outline
                  onClick={() =>
                    toggleSessionMenuOpen(false) || setSessionInputValue('')
                  }
                >
                  {tFallback('LABEL_CANCEL', 'Cancel')}
                </Button>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem>
        {!!selectedSessionId && (
          <React.Fragment>
            <NavItem className="ml-2">
              <Button
                color="light"
                outline
                onClick={() => setShowConfirmReset(true)}
                className="d-flex flex-row align-items-center justify-content-center"
                style={{ minWidth: '100px' }}
              >
                <RefreshCcw size="14" className="mr-1">
                  {tFallback('LABEL_RESET', 'Reset')}
                </RefreshCcw>
                <span>{tFallback('LABEL_RESET', 'Reset')}</span>
              </Button>
            </NavItem>
            {selectedSessionId.toLowerCase() === 'main' && (
              <NavItem className="ml-2">
                <Button
                  disabled={currentMonth === 0}
                  color="light"
                  outline
                  onClick={() => {
                    setShowConfirmRollback(true);
                  }}
                  className="d-flex flex-row align-items-center justify-content-center"
                  style={{ minWidth: '100px' }}
                >
                  <Rewind size="14" className="mr-1" />
                  <div>{tFallback('LABEL_ROLL_BACK', 'Rollback')}</div>
                </Button>
              </NavItem>
            )}
          </React.Fragment>
        )}
      </Nav>
    </Navbar>
  );
};

export default FacilitatorNavbar;
