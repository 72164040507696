import { useEffect, useRef } from 'react';

export default function useOnChangeVisibility(cb) {
  const cbRef = useRef(null);

  if (cbRef.current === null) {
    cbRef.current = cb;
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', e =>
      cbRef.current(e.target.visibilityState)
    );
    // TODO: if we want to handle cleanup removing the listener then we have to do some (craziness?) to get a stable handler function.
  }, []);
}
