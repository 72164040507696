import * as types from '../../redux-constants';
import _ from 'lodash';

const initialState = {
  isLoaded: false,
  historicalCompanyScores: [],
  timeAllocations: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_REPORT_STATE:
      return _.assign({}, action.payload, { isLoaded: true });
    default:
      return state;
  }
}
