import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from 'reactstrap';
import useConfig from '../hooks/use-config';

export default (props) => {
  const { tFallback } = useConfig();
  const [showModal, setShowModal] = useState(true);
  const [confirmText, setConfirmText] = useState('');

  const requireConfirmText = props?.confirmationText;

  const isConfirmed =
    !requireConfirmText ||
    confirmText.toLowerCase().trim() === props.confirmationText.toLowerCase();

  return (
    <Modal isOpen={showModal} toggle={() => setShowModal(true)}>
      <ModalHeader>{props.titleLabel}</ModalHeader>
      <ModalBody>
        {props.bodyLabel}
        {requireConfirmText && (
          <div>
            <div>
              <p>
                {tFallback(
                  'LABEL_PLEASE_CONFIRM_BY_TYPING',
                  'Please confirm by typing'
                )}{' '}
                "<span style={{ color: 'red' }}>{props.confirmationText}</span>"{' '}
                {tFallback('LABEL_BELOW', 'below')}:
              </p>
              <p />
            </div>
            <Input
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              onKeyDown={(e) => setConfirmText(e.target.value)}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ minWidth: '75px' }}
          color="dark"
          outline
          onClick={() => {
            setShowModal(false);
            props.onClickDismissButton && props.onClickDismissButton();
          }}
        >
          {tFallback('LABEL_CANCEL', 'Cancel')}
        </Button>
        <Button
          style={{ minWidth: '125px' }}
          color="info"
          disabled={!isConfirmed}
          onClick={() => {
            setShowModal(false);
            props.onClickActionButton();
          }}
        >
          {props.actionButtonLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
