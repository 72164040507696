import React, { useEffect } from 'react';
import _ from 'lodash';

import Wrapper from '../components/Wrapper';
import FacilitatorSidebar from '../components/FacilitatorSidebar';
import Main from '../components/Main';

import { connect } from 'react-redux';
import { Spinner, Container, Row, Col, UncontrolledAlert } from 'reactstrap';

import { HelpCircle } from 'react-feather';
import * as types from '../redux/redux-constants';
import { getFacilitatorState } from '../services/facilitatorApiService';

import Content from '../components/Content';
import FacilitatorNavbar from '../components/FacilitatorNavbar';
import useOnChangeVisibility from '../hooks/use-on-change-visibility';
import useConfig from '../hooks/use-config';

const Facilitator = (props) => {
  const { tFallback } = useConfig();
  useEffect(() => {
    getFacilitatorState();
  }, []);

  useOnChangeVisibility((visibility) => {
    if (visibility === 'visible') {
      getFacilitatorState();
    }
  });

  if (!props.allSessionIds) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <Wrapper>
        <FacilitatorSidebar />
        <Main>
          <FacilitatorNavbar
            handleToggleSidebar={() =>
              props.dispatch({
                type: types.SIDEBAR_VISIBILITY_TOGGLE,
              })
            }
            currentMonth={props.currentMonth}
            isMonthActive={props.isMonthActive}
            allSessionIds={props.allSessionIds}
            selectedSessionId={props.selectedSessionId}
          />
          <Content>
            {!!props.selectedSessionId ? (
              props.children
            ) : (
              <Container fluid>
                <Row>
                  <Col className="pt-3">
                    <UncontrolledAlert
                      color="primary"
                      className="alert-outline"
                    >
                      <div className="alert-icon">
                        <HelpCircle />
                      </div>
                      <div className="alert-message">
                        <strong>
                          {tFallback('LABEL_HELLO_THERE', 'Hello there!')}
                        </strong>{' '}
                        {tFallback(
                          'LABEL_SELECT_A_SESSION_TO_GET_STARTED',
                          'Select a session to get started.'
                        )}
                      </div>
                    </UncontrolledAlert>
                  </Col>
                </Row>
              </Container>
            )}
          </Content>
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

// TODO: show loading indicator when switching or resetting sessions

export default connect((store) => ({
  currentMonth: store.facilitatorState.currentMonth,
  isMonthActive: store.facilitatorState.isMonthActive,
  allSessionIds: _.reject(
    store.facilitatorState.allSessionIds,
    (sessionId) => sessionId.indexOf('tutorial') > -1
  ),
  selectedSessionId: store.facilitatorState.selectedSessionId,
}))(Facilitator);
