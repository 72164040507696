import React from 'react';
import { connect } from 'react-redux';
import { calcTotalScore } from '../redux/reducers/player/gameStateReducer';

import { Collapse, Nav, Navbar, NavItem, Button } from 'reactstrap';

import { Calendar, Clock, Flag } from 'react-feather';
import * as types from '../redux/redux-constants';

import Timer from './Timer';
import store from '../redux/store';
import { ACTIVATE_TUTORIAL } from '../redux/redux-constants';
import useConfig from '../hooks/use-config';

const NavbarComponent = ({
  dispatch,
  currentMonth,
  totalScore,
  secondsRemaining,
  isTutorialActive,
  materialsLink,
}) => {
  const { t } = useConfig();

  const handleStartTutorial = () => {
    store.dispatch({
      type: ACTIVATE_TUTORIAL,
      payload: 120,
    });
  };

  return (
    <Navbar id="navbar" color="primary" light expand>
      <span
        className="sidebar-toggle d-flex mr-2 text-white"
        onClick={() => {
          dispatch({
            type: types.SIDEBAR_VISIBILITY_TOGGLE,
          });
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          {secondsRemaining !== -1 && (
            <NavItem className="tutorial-timer text-light-purple d-flex align-items-center justify-content-center mr-4">
              <Clock size={20} />
              <span className="ml-2 font-weight-bold text-lg text-white">
                <Timer serverSecondsRemaining={secondsRemaining} />
              </span>
            </NavItem>
          )}

          <NavItem className="tutorial-round text-light-purple d-flex align-items-center justify-content-center mr-4">
            <Calendar size={20} />
            <span className="ml-2 font-weight-bold text-lg text-white">
              {t`MONTH`} {currentMonth} (Q
              {currentMonth && Math.ceil(currentMonth / 3)})
            </span>
          </NavItem>

          <NavItem className="text-light-purple d-flex align-items-center justify-content-center mr-4">
            <Flag size={20} />
            <span className="ml-1">{t`SCORE`}</span>
            <span className="ml-2 font-weight-bold text-lg text-white">
              {totalScore} {t`LABEL_POINTS_ABBREVIATION`}
            </span>
          </NavItem>
          {materialsLink && (
            <NavItem className="mr-2 p-1">
              <div className="text-white">
                <Button
                  color="light"
                  outline
                  href={materialsLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t`PARTICIPANT_WORKBOOK`}
                </Button>
              </div>
            </NavItem>
          )}
          <NavItem className="p-1">
            <div className="text-white">
              {!isTutorialActive && (
                <Button color="light" outline onClick={handleStartTutorial}>
                  {t`LAUNCH_TUTORIAL`}
                </Button>
              )}
            </div>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default connect((state) => ({
  app: state.app,
  currentMonth: state.gameState.playerContext.currentMonth,
  totalScore: calcTotalScore(state.gameState),
  // notifications: state.notification.notifications,
  secondsRemaining: state.gameState.secondsRemaining,
  isTutorialActive: state.player.isTutorialActive,
  materialsLink:
    state.gameState.playerContext?.participantResources[
      'Participant Materials'
    ],
}))(NavbarComponent);
