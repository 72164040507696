import React, { useEffect, useState, useRef } from 'react';
import useInterval from '../hooks/use-interval';

export default ({ serverSecondsRemaining }) => {
  const [currentSecondsRemaining, setCurrentSecondsRemaining] = useState(null);
  const prevServerSecondsRemaining = usePrevious(serverSecondsRemaining);

  useEffect(() => {
    if (
      currentSecondsRemaining === null ||
      (prevServerSecondsRemaining !== serverSecondsRemaining &&
        Math.abs(serverSecondsRemaining - currentSecondsRemaining) >= 3)
    ) {
      setCurrentSecondsRemaining(
        serverSecondsRemaining > -1 ? serverSecondsRemaining : null
      );
    }
  }, [
    serverSecondsRemaining,
    currentSecondsRemaining,
    prevServerSecondsRemaining,
  ]);

  useInterval(
    () => {
      setCurrentSecondsRemaining(currentSecondsRemaining - 1);
    },
    currentSecondsRemaining !== null && currentSecondsRemaining >= 0
      ? 1000
      : null
  );

  return currentSecondsRemaining > 0 ? (
    <span className={currentSecondsRemaining < 60 ? 'bg-danger text-white rounded-lg px-2' : ''}>
      {Math.floor(currentSecondsRemaining / 60) +
        ':' +
        ('0' + (currentSecondsRemaining % 60)).slice(-2)}
    </span>
  ) : (
    <span>0:00</span>
  );
};

function usePrevious(value) {
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}
