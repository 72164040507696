import notification from './notificationReducer';
import gameState from './gameStateReducer';
import player from './playerReducer';
import reports from './reportStateReducer';
import conversations from './conversationStateReducer';

export default {
  player,
  gameState,
  notification,
  reports,
  conversations,
};
