import _ from 'lodash';
import {
  SET_FACILITATOR_STATE,
  GET_GAME_STATE,
  GET_CONVERSATION_STATE,
  SET_FACILITATOR_SESSION_ID,
  ROLLBACK_ROUND_COMPLETE,
  SET_FACILITATOR_ALL_SESSION_IDS
} from "../redux-constants";
import {
  getFacilitatorReportState,
  getFacilitatorLatestReportState,
  getFacilitatorState,
  getFacilitatorLatestDashboardReportState
} from '../../services/facilitatorApiService';

import {isFacilitatorReportsView} from "../../utils";

export default store => next => action => {
  const getSelectedSessionId = () => store.getState().facilitatorState.selectedSessionId;

  // Always accept allSessionIds anytime there is an action with allSessionIds state.
  const currentAllSessionsLength = store.getState().facilitatorState.allSessionIds.length;
  if (action.payload && action.payload.allSessionIds) {

    if (action.payload.allSessionIds.length !== currentAllSessionsLength) {
      next({
        type: SET_FACILITATOR_ALL_SESSION_IDS,
        payload: action.payload.allSessionIds
      });
    }

    if (getSelectedSessionId() === null) {

      const mainSession = _.filter(action.payload.allSessionIds, (sId) =>
        sId.toLowerCase() === 'main'
      )[0];

      if (mainSession) {
        store.dispatch({
          type: SET_FACILITATOR_SESSION_ID,
          payload: mainSession
        });
        // The current action should be a SET_FACILITATOR_STATE but it was based on no selected session.
        // An alt approach would be that the first request a facilitator with no session would make is for a
        // list of sessionIds. Or the server could notice the lack of session Id and use "main" if it existed.
        getFacilitatorState();
        return;
      }
    }
  }

  if (action.sessionId && action.sessionId !== getSelectedSessionId()) {
    return;
  }

  // TODO: much cleaner if we broke out action types into seperate files or exported grouped objects.
  if (_.includes([GET_GAME_STATE, GET_CONVERSATION_STATE,], action.type)) {
    return;
  }

  if (action.type === SET_FACILITATOR_SESSION_ID) {
    if (action.payload) {
      localStorage.setItem('facilitatorSessionId', action.payload);
    } else {
      localStorage.removeItem('facilitatorSessionId');
    }
  }

  // TODO: this is weird logic since it handles with and without sessionId on payload for this action.
  //       Ideally we'd use a new SET_INITIAL_FACILITATOR_STATE action instead and breakout use case.
  if (action.type === SET_FACILITATOR_STATE) {
    if (_.includes(action.payload.allSessionIds, getSelectedSessionId())) {
      // Grab report state too if this is a valid session.
      // noinspection JSIgnoredPromiseFromCall
      if (store.getState().facilitatorReports.isLoaded) {
        if (isFacilitatorReportsView()) {
          getFacilitatorLatestReportState();
        } else {
          getFacilitatorLatestDashboardReportState();
        }
      } else {
        getFacilitatorReportState();
      }
    } else {
      // Invalid or empty session. Skip report state but continue below. We need to set the list of allSessions
      localStorage.removeItem('facilitatorSessionId');
      next({
        type: SET_FACILITATOR_SESSION_ID,
        payload: null,
      });
    }
  }

  if (action.type === ROLLBACK_ROUND_COMPLETE) {
    getFacilitatorState();
  }

  next(action);
};
