import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Container,
  Badge,
} from 'reactstrap';
import { getEmployeeImageFile } from '../../../utils';
import { getReportTokenCategoryType, getReportTokenType } from '../../../utils';
import useConfig from '../../../hooks/use-config';

function EmployeeDetails(props) {
  const { tFallback } = useConfig();
  const { employees, timeAllocations, projects, month, theme } = props;
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(employees[0].id);

  const selectedEmployee = _.find(employees, { id: selectedEmployeeId });

  const employeeProjectsByMonth = _.groupBy(
    _.filter(projects, (p) =>
      _.some(p.assignedEmployees, { id: selectedEmployeeId })
    ),
    'monthEnded'
  );

  const employeeTimeAllocationsByMonth = _.groupBy(
    _.filter(timeAllocations, { employeeId: selectedEmployeeId }),
    'month'
  );

  function translateSkillDescription(skillDescription) {
    switch (skillDescription.toLowerCase()) {
      case 'beginner':
        return tFallback('LABEL_BEGINNER', 'Beginner');
      case 'novice':
        return tFallback('LABEL_NOVICE', 'Novice');
      case 'professional':
        return tFallback('LABEL_PROFESSIONAL', 'Professional');
      case 'expert':
        return tFallback('LABEL_EXPERT', 'Expert');
      default:
        return skillDescription;
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Row className="d-flex flex-row align-items-center bg-light-gray rounded-lg py-2 mb-3">
            <Col md={4}>
              <div className="d-flex flex-column">
                <h4>{tFallback('LABEL_SELECT_EMPLOYEE', 'Select Employee')}</h4>
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret color="info">
                    {selectedEmployee.firstName +
                      ' ' +
                      selectedEmployee.lastName}
                    &nbsp;
                  </DropdownToggle>
                  <DropdownMenu>
                    {employees.map((e) => (
                      <DropdownItem
                        key={e.id}
                        onClick={() => setSelectedEmployeeId(e.id)}
                      >
                        <img
                          src={getEmployeeImageFile(e.imageFileName)}
                          alt={e.firstName}
                          className="rounded-circle mr-2"
                          style={{ height: '40px', width: '40px' }}
                        />
                        <span>{e.firstName + ' ' + e.lastName}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </Col>
            <Col md={8}>
              <Row className="d-flex flex-row align-items-center">
                <img
                  src={getEmployeeImageFile(selectedEmployee.imageFileName)}
                  alt={selectedEmployee.firstName}
                  className="rounded-circle mr-4 ml-3"
                  style={{ height: '75px', width: '75px' }}
                />
                <div className="d-flex flex-column">
                  <h4 className="mb-0">{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</h4>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex flex-column justify-content-center mr-4">
                      <div>{tFallback('LABEL_FUNCTIONAL', 'Functional')}</div>
                      <Badge
                        pill
                        style={{ background: props.theme.functional }}
                      >
                        {selectedEmployee.stats.functional.number}{' '}
                        {translateSkillDescription(
                          selectedEmployee.stats.functional.description
                        )}
                      </Badge>
                    </div>
                    <div className="d-flex flex-column justify-content-center mr-4">
                      <div>
                        {tFallback('LABEL_COMMUNICATION', 'Communication')}
                      </div>
                      <Badge
                        pill
                        style={{ background: props.theme.communication }}
                      >
                        {selectedEmployee.stats.interpersonal.number}{' '}
                        {translateSkillDescription(
                          selectedEmployee.stats.interpersonal.description
                        )}
                      </Badge>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <div>{tFallback('LABEL_ENGAGEMENT', 'Engagement')}</div>
                      <Badge pill color="primary">
                        {selectedEmployee.stats.motivation.number}
                      </Badge>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>

          {_.flatMap(_.range(1, month + 1).reverse(), (m) => (
            <Col key={m}>
              <Row className="d-flex align-items-center justify-content-between mt-3 pt-3 border-top">
                <div style={{ color: theme.primary }}>
                  {tFallback('MONTH', 'Month')} {m}
                </div>
                {!employeeProjectsByMonth[m] && (
                  <Badge color="dark">
                    {tFallback(
                      'LABEL_NO_PROJECTS_COMPLETED',
                      'No Projects Completed'
                    )}
                  </Badge>
                )}
              </Row>
              {employeeProjectsByMonth[m] &&
                employeeProjectsByMonth[m].map((p) => {
                  const firedActionRules = _.filter(p.firedActionRules, {
                    employeeName: selectedEmployee.firstName,
                  });

                  return (
                    <Row key={p.id}>
                      <Col>
                        <div className="my-2" style={{ fontSize: '15px' }}>
                          {p.name}
                        </div>
                        {firedActionRules.map((ar) => (
                          <Row key={ar.id} className="d-flex flex-row">
                            <Col style={{ flex: 0.25 }}>
                              <div>
                                {tFallback(
                                  'LABEL_TASK_QUALITY_POINTS',
                                  'Task Quality Points'
                                )}
                                : {ar.companyPoints}
                              </div>
                            </Col>
                            <Col style={{ flex: 0.25 }}>
                              <div>
                                {tFallback('LABEL_ENGAGEMENT', 'Engagement')}:{' '}
                                {ar.engagementEffect ||
                                  tFallback(
                                    'LABEL_NOT_APPLICABLE_ABBREVIATION',
                                    'N/A'
                                  )}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  );
                })}
              {employeeTimeAllocationsByMonth[m] && (
                <Row>
                  <Col>
                    <div className="my-2 border-bottom font-weight-bold pb-1">
                      {tFallback(
                        'LABEL_COACHING_AND_RECOGNITION',
                        'Coaching and Recognition'
                      )}
                    </div>
                    {employeeTimeAllocationsByMonth[m].map((ta) => {
                      const firedActionRules = _.filter(ta.firedActionRules, {
                        employeeName: selectedEmployee.firstName,
                      });

                      return (
                        <Row className="mt-1" key={ta.tokenId}>
                          <Col>
                            <div className="text-primary font-weight-bold">
                              {getReportTokenType(ta, tFallback)} (
                              {_.capitalize(
                                getReportTokenCategoryType(ta, tFallback)
                              )}
                              )
                            </div>
                            {
                              // Still need to render effect even if there aren't firedActionRules
                              (firedActionRules || [{}]).map((ar, i) => (
                                <Row
                                  key={ar.id || i}
                                  className="d-flex flex-row mt-1"
                                >
                                  <Col md={8}>
                                    {ar.responseText ||
                                      `${tFallback(
                                        'LABEL_NO_RESPONSE',
                                        'No Response'
                                      )}.`}
                                  </Col>
                                  <Col md={4}>
                                    {getTokenEffectLabel(ta, tFallback)}
                                  </Col>
                                </Row>
                              ))
                            }
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              )}
            </Col>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default connect((state) => ({
  employees: [
    ...state.gameState.employees,
    ...state.gameState.teammateEmployees,
  ],
  projects: state.gameState.projects,
  timeAllocations: _.filter(state.reports.timeAllocations, 'employeeId').map(
    (ta) =>
      _.assign({}, ta, {
        firedActionRules: _.filter(state.gameState.firedActionRules, {
          tokenId: ta.tokenId,
        }),
      })
  ),
  month: state.gameState.month,
  theme: state.theme.currentTheme,
}))(EmployeeDetails);

function getTokenEffectLabel(timeAllocation, tFallback) {
  const valueType =
    getReportTokenType(timeAllocation, tFallback) ===
    tFallback('LABEL_COACHING', 'Coaching')
      ? _.capitalize(getReportTokenCategoryType(timeAllocation, tFallback))
      : tFallback('LABEL_ENGAGEMENT', 'Engagement');

  const pointPrefix = timeAllocation.points > 0 ? '+' : '';

  // TODO: improve name? timeAllocation.points = engagement or skill impact (delta)
  return valueType + ': ' + pointPrefix + timeAllocation.points;
}
