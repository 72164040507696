import * as types from '../../redux-constants';
import _ from 'lodash';

const initialState = {
  isLoaded: false,
  companyRank: 1,
  companyScore: 0,
  employees: [],
  teammateEmployees: [],
  projects: [],
  tokens: [],
  teammateTokens: [],
  recognitionTypes: [],
  reports: {},
  timeRemaining: {},
  playerContext: {},
  firedActionRules: [],
  secondsRemaining: -1, // Used for default since keys with null values are excluded by Java JSON library.
};

export const BUSINESS_PLANNING_PROJECT_ID = 73;

const initialTutorialState = {
  isLoaded: true,
  isMonthActive: true,
  companyRank: 1,
  companyScore: 32,
  tokens: [
    {
      id: 1,
      usageDescription: ['Recognized', 'Jim'],
    },
    {
      id: 2,
      usageDescription: null,
    },
    {
      id: 3,
      usageDescription: null,
    },
  ],
  projects: [
    {
      id: 73,
      name: 'Business Planning',
      type: 'collaborative',
      typeLabel: 'Collaborative',
      statusLabel: 'Due This Month',
      status: 'Not Started',
      isActive: false,
      isCompleted: false,
      isApplied: false,
      delegated: false,
      delegatedEmployeeId: 0,
      monthEnded: 1,
      companyName: 'Lion',
      projectPoints: 42,
      totalScore: 0,
      stats: {
        functional: {
          past: 0,
          current: 0,
          total: 11,
        },
        interpersonal: {
          past: 0,
          current: 0,
          total: 8,
        },
      },
      assignedEmployees: [],
      firedActionRules: [],
      employeeScores: [],
      assignedTokens: [],
      archivedAssignedEmployees: [],
    },
  ],
};

export default function reducer(state = initialState, action) {
  // This is for development purposes. If used in production, these types of assertions should be in middleware.
  // TODO: turn this off in production
  // if (
  //   action.type === types.SET_GAME_STATE &&
  //   _.difference(_.keys(action.payload), _.keys(initialState)).length
  // ) {
  //   throw new Error(
  //     `Unknown game state keys: ${_.difference(
  //       _.keys(action.payload),
  //       _.keys(initialState).join(' ')
  //     )}`
  //   );
  // }

  switch (action.type) {
    case types.SET_GAME_STATE:
      // TODO: review. Picked the easiest solution to breakout reports/messages for now but others could be better.
      return _.assign({}, action.payload, { isLoaded: true });
    case types.SET_TIMER_UPDATE:
      return _.assign({}, state, { secondsRemaining: action.payload });
    case types.ACTIVATE_TUTORIAL:
      return _.assign({}, state, initialTutorialState);
    case types.DEACTIVATE_TUTORIAL: {
      return _.assign({}, state, { isLoaded: true });
    }

    default:
      return state;
  }
}

export function calcTotalScore(gameState) {
  return _(gameState.projects)
    .filter({ status: 'Completed' })
    .sumBy('totalScore');
}

export function getFiredActionRulesForEmployee(gameState, firstName) {
  return _.filter(gameState.firedActionRules, { employeeName: firstName });
}

export function getTeamEmployees(gameState) {
  const allEmployees = _.concat(
    gameState.employees,
    gameState.teammateEmployees
  );

  return _.map(allEmployees, (e) => {
    const activeProject = _.find(
      gameState.projects,
      ({ monthEnded, status, assignedEmployees }) =>
        _.some(assignedEmployees, { id: e.id }) &&
        (status === 'Active' || monthEnded === gameState.month)
    );

    const isAssigned = !!activeProject;

    return _.assign({}, e, {
      firedActionRules: getFiredActionRulesForEmployee(gameState, e.firstName),
      isTeammateEmployee: !_.find(gameState.employees, { id: e.id }),
      isAssigned,
      status: isAssigned ? activeProject.name : 'Not Assigned',
      assignedProject: isAssigned
        ? {
            id: activeProject.id,
            status: activeProject.status.toUpperCase(), // TODO: before merging, confirm toUpperCase was correct.
            name: activeProject.name,
          }
        : {},
    });
  });
}
