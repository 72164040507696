import * as types from '../../redux-constants';

const initialState = {
  notifications: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, actions.payload],
      };

    default:
      return state;
  }
}
