import _ from 'lodash';
import * as types from '../../redux-constants';

const initialState = {
  trainingStatus: null, // { type, employeeId, points }
  isTeammateView: false,
  isTutorialActive: false,
};

// NOTE: This is the local only player reducer that doesn't handle game state
export default function playerReducer(state = initialState, action) {
  switch (action.type) {
    case types.START_TRAINING_EMPLOYEE:
      return {
        ...state,
        trainingStatus: {
          type: action.payload.type,
          employeeId: action.payload.employeeId,
          points: null,
        },
      };

    case types.SET_TRAINING_RESULT_POINTS: {
      return {
        ...state,
        trainingStatus: _.assign({}, state.trainingStatus, {
          points: action.payload,
        }),
      };
    }

    case types.END_TRAINING_EMPLOYEE:
      return {
        ...state,
        trainingStatus: null,
      };

    case types.SET_TEAMMATE_VIEW: {
      return {
        ...state,
        isTeammateView: action.payload,
      };
    }

    case types.ACTIVATE_TUTORIAL: {
      return {
        ...state,
        isTutorialActive: true,
      };
    }

    case types.DEACTIVATE_TUTORIAL: {
      window.location.reload();
      return {
        ...state,
        isTutorialActive: false,
      };
    }

    default:
      return state;
  }
}
