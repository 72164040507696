import React, { useState } from 'react';
import _ from 'lodash';
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { connect } from 'react-redux';

import { getEmployeeImageFile } from '../../../utils';
import useConfig from '../../../hooks/use-config';

function CompletedProjects(props) {
  const { tFallback } = useConfig();
  const { completedProjects } = props;
  const [selectedProjectId, setSelectedProjectId] = useState(
    completedProjects.length ? completedProjects[0].id : null
  );

  if (completedProjects.length === 0) {
    return (
      <div>{tFallback('LABEL_NO_COMPLETED_TASKS', 'No completed tasks.')}</div>
    );
  }

  const selectedProject = _.find(completedProjects, { id: selectedProjectId });

  // TODO: consider showing all completed projects in table or some such and then clicking into or expanding to show details
  return (
    <Container>
      <Row>
        <Col>
          <Row className="d-flex flex-row align-items-center bg-light-gray rounded-lg py-2 mb-3">
            <Col sm={12}>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div>{tFallback('LABEL_SELECT_PROJECT', 'Select Project')}</div>
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret color="info">
                    {selectedProject.name}
                    &nbsp;
                  </DropdownToggle>
                  <DropdownMenu>
                    {completedProjects.map((p) => (
                      <DropdownItem
                        key={p.id}
                        onClick={() => setSelectedProjectId(p.id)}
                      >
                        {p.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex align-items-center justify-content-between">
                <h4>
                  {tFallback('LABEL_COMPLETED', 'Completed')}:{' '}
                  <span className="text-info">
                    {tFallback('MONTH', 'Month')} {selectedProject.monthEnded}
                  </span>
                </h4>
                <h4>
                  {tFallback('LABEL_TASK_POINTS', 'Task Points')}:{' '}
                  <span className="text-info">
                    {selectedProject.projectPoints}
                  </span>
                </h4>
                <h4>
                  {tFallback(
                    'LABEL_TASK_QUALITY_POINTS',
                    'Task Quality Points'
                  )}
                  :{' '}
                  <span className="text-info">
                    {_.sumBy(selectedProject.firedActionRules, 'companyPoints')}
                  </span>
                </h4>
              </div>
              {
                // TODO: handle supported and delegated
                _.flatMap(selectedProject.assignedEmployees, (e) =>
                  (
                    _.filter(selectedProject.firedActionRules, {
                      employeeName: e.firstName,
                    }) || [{}]
                  ).map((ar) => (
                    <Row
                      key={ar.id}
                      className="d-flex flex-row align-items-center my-2 border-top pt-2"
                    >
                      <Col sm={2}>
                        <img
                          src={getEmployeeImageFile(e.imageFileName)}
                          alt={e.firstName}
                          className="rounded-circle mr-4"
                          style={{ height: '75px', width: '75px' }}
                        />
                      </Col>
                      <Col sm={5}>
                        <div>
                          {ar.responseText ||
                            `${tFallback('LABEL_NO_RESPONSE', 'No Response')}.`}
                        </div>
                        <div>
                          {selectedProject.delegatedEmployeeId === e.id && (
                            <Badge pill color="primary">
                              {tFallback('LABEL_DELEGATED', 'Delegated')}
                            </Badge>
                          )}
                          {e.supported && (
                            <Badge pill color="secondary">
                              {tFallback('LABEL_SUPPORTED', 'Supported')}
                            </Badge>
                          )}
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div>
                          {tFallback(
                            'LABEL_TASK_QUALITY_POINTS',
                            'Task Quality Points'
                          )}
                          : {ar.companyPoints}
                        </div>
                      </Col>
                      <Col sm={2} className="text-right">
                        <div>
                          {tFallback('LABEL_ENGAGEMENT', 'Engagement')}:{' '}
                          {ar.engagementEffect || 0}
                        </div>
                      </Col>
                    </Row>
                  ))
                )
              }
            </Col>
          </Row>
        </Col>
      </Row>
      {/*<Table striped>*/}
      {/*  <thead>*/}
      {/*  <tr>*/}
      {/*    <th>MonthEnded</th>*/}
      {/*    <th>Name</th>*/}
      {/*    <th className="text-right" width="10%">Points</th>*/}
      {/*  </tr>*/}
      {/*  </thead>*/}
      {/*  <tbody>*/}
      {/*  {*/}
      {/*    completedProjects.map(p =>*/}
      {/*      <tr key={p.id}>*/}
      {/*        <td>{ p.monthEnded }</td>*/}
      {/*        <td>{ p.name }</td>*/}
      {/*        <td className="text-right" width="10%">{ p.projectPoints }</td>*/}
      {/*      </tr>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  </tbody>*/}
      {/*</Table>*/}
    </Container>
  );
}

export default connect((state) => ({
  completedProjects: _.filter(state.gameState.projects, {
    status: 'Completed',
  }),
}))(CompletedProjects);
