import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function. It's ok from functionality perspective that this callback will be assigned to ref
  // each time parent re-renders. However, parent can memoize callback to avoid no-op resets (e.g. for performance).
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick(intervalId) {
      savedCallback.current();
    }

    // Only create interval and provide cleanup if delay is non-null.
    // The created interval will continue to run as long as delay doesn't change.
    if (delay !== null) {
      const id = setInterval(() => tick(id), delay);
      // The cleanup will get called on unmount *OR* if the delay changes causing the effect to fire again.
      return () => clearInterval(id);
    }
    // else don't create the interval if the delay is null, and don't return a cleanup fn because there is no interval to clear.
  }, [delay]);
}
