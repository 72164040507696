import * as types from '../../redux-constants';
import _ from 'lodash';

const initialState = {
  isLoaded: false,
  channels: [], // TODO: we are so close to being able to infer channels and creating via the reducer instead of sending from server.
  messages: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_CONVERSATION_STATE:
      const conversationState = action.payload;
      return _.assign({}, conversationState, {
        // TODO: review. We need the answerId key to exist on messages for proper handling in component, as well as it being cleaner.
        //       Issue is that keys with null values in JSONObject Java library are omitted entirely. If you use their JSONOBJECT.NULL
        //       you end up with an empty object in the client.
        //       Most friendly solution is to describe entire state, with all fields, in js constants, map over keys in
        //       applicable state reducers, and insert missing keys with null values.
        //       https://stackoverflow.com/questions/44631604/unable-to-put-null-values-in-json-object
        messages: conversationState.messages.map(m =>
          _.assign({}, m, {
            answerId: m.answerId || null, // TODO: required
          })
        ),
        isLoaded: true,
      });
    default:
      return state;
  }
}

export function getActiveMessageToPrompt(conversationState) {
  return _.findLast(conversationState.messages, 'shouldPrompt');
}
