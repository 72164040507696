import React from 'react';
import { Line } from 'react-chartjs-2';
import { Table, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  getCompanyScores,
  sortScores,
  getEngagementScores,
  getScoreRank,
} from '../../helpers';
import useConfig from '../../hooks/use-config';

function generateMonths(total, tFallback) {
  return _.range(0, total + 1).map((month) => tFallback('MONTH','Month') + ' ' + month);
}

function ScoreCardReport(props) {
  const { tFallback } = useConfig();
  const groupedCompanyScores = getCompanyScores(
    props.facilitatorReports.historicalCompanyScores,
    props.facilitatorReports.filteredTeams,
    props.totalMonths
  );

  const teamNames = Object.keys(props.facilitatorReports.filteredTeams);

  const sortedCompanyScores = sortScores(groupedCompanyScores);

  const groupedAverageEngagement = getEngagementScores(
    props.facilitatorReports.employees
  );

  const filteredTeams = props.noFilter
    ? teamNames
    : teamNames.filter((x) => !!props.facilitatorReports.filteredTeams[x]);
  const data = {
    labels: generateMonths(props.totalMonths, tFallback),
    datasets: filteredTeams.map((teamName, i) => {
      return {
        label: teamName,
        fill: true,
        backgroundColor: 'transparent',
        borderColor: props.reportTeamColors[i],
        data: groupedCompanyScores[teamName],
      };
    }),
  };

  const rankedCompanyScores = Object.keys(groupedCompanyScores).reduce(
    (acc, team) => {
      acc[team] = Object.assign(groupedCompanyScores[team], {
        rank: getScoreRank(groupedCompanyScores, team, tFallback),
      });
      return acc;
    },
    {}
  );

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: 'rgba(0,0,0,0.05)',
          },
        },
      ],
      yAxes: [
        {
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: 'rgba(0,0,0,0)',
            fontColor: '#fff',
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Row className="mb-5 pb-4" noGutters>
        <Col md={12} style={{ height: '300px' }}>
          <h4 className="mb-3">
            {tFallback('LABEL_TASK_SCORES', 'Task Scores')}
          </h4>
          <Line data={data} options={options} />
        </Col>
      </Row>

      {!props.excludeTable ? (
        props.totalMonths > 0 ? (
          <Table striped>
            <thead>
              <tr>
                <th>{tFallback('TEAM_RECOGNITION_DISPLAY', 'Team')}</th>
                <th className="text-right" width="20%">
                  {tFallback('LABEL_TASK_SCORE', 'Task Score')}
                </th>
                <th className="text-right" width="5%">
                  {tFallback('LABEL_RANK', 'Rank')}
                </th>
                <th className="text-right" width="5%">
                  {tFallback('LABEL_ENGAGEMENT', 'Engagement')}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredTeams.map((teamName, i) => {
                const textType =
                  groupedAverageEngagement[teamName] >= 60
                    ? 'text-secondary'
                    : groupedAverageEngagement[teamName] >= 40 &&
                      groupedAverageEngagement[teamName] < 60
                    ? 'text-warning'
                    : 'text-danger';

                if (rankedCompanyScores[teamName]) {
                  return (
                    <tr key={i}>
                      <td>
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="mr-3"
                          style={{ color: props.reportTeamColors[i] }}
                        />
                        {teamName}
                      </td>
                      <td className="text-right">
                        {rankedCompanyScores[teamName][props.totalMonths]}
                      </td>
                      <td
                        className={`text-right ${
                          ['1st', '2nd', '3rd'].includes(
                            rankedCompanyScores[teamName].rank
                          )
                            ? 'text-success'
                            : 'text-dark'
                        }`}
                      >
                        {rankedCompanyScores[teamName].rank}
                      </td>
                      <td className={`text-right ${textType}`}>
                        {groupedAverageEngagement[teamName]}
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={i}>
                      <td>
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="mr-3"
                          style={{ color: props.reportTeamColors[i] }}
                        />
                        {teamName}
                      </td>
                      <td className="text-right">0</td>
                      <td className="text-right text-success">
                        {sortedCompanyScores.length + 1}
                      </td>
                      <td className="text-right">0</td>
                      <td className={`text-right ${textType}`}>
                        {groupedAverageEngagement[teamName]}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        ) : (
          <h4>
            {tFallback(
              'LABEL_DATA_NOT_YET_AVAILABLE',
              'This data is not yet available.'
            )}
          </h4>
        )
      ) : (
        <div />
      )}
    </React.Fragment>
  );
}

export default connect((state) => ({
  reportTeamColors: state.theme.reportTeamColors,
  totalMonths: state.facilitatorState.currentMonth,
  facilitatorReports: state.facilitatorReports,
}))(ScoreCardReport);
