import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import useConfig from '../../hooks/use-config';

const effectMapper = {
  engagement: 'Eng',
  functional: 'Func',
  communication: 'Comm',
};
function ConversationResponses(props) {
  const { tFallback } = useConfig();
  const groupedConversationAnswers = _.groupBy(
    props.conversationAnswers,
    'questionId'
  );
  const tableData = Object.keys(groupedConversationAnswers).map(
    (questionId) => {
      return (
        <ul className="mt-2 w-100" style={{ listStyleType: 'none' }}>
          {groupedConversationAnswers[questionId].map((answer) => {
            const spanClass = answer.effectPoints.includes('+')
              ? 'text-success'
              : answer.effectPoints.includes('-')
              ? 'text-danger'
              : 'text-gray';
            return (
              <li
                key={answer.questionId + '' + answer.companyName}
                className="ml-2"
              >
                {answer.companyName} - {answer.answer}{' '}
                <span className={spanClass + ' float-right'}>
                  ({effectMapper[answer.effectCategory]} {answer.effectPoints})
                </span>{' '}
              </li>
            );
          })}
        </ul>
      );
    }
  );
  const donutData = Object.keys(groupedConversationAnswers).map(
    (questionId) => {
      const groupedAnswers = _.groupBy(
        groupedConversationAnswers[questionId],
        'answer'
      );
      const answers = Object.keys(groupedAnswers);
      return {
        datasets: [
          {
            data: answers.map((answer) => groupedAnswers[answer].length),
            backgroundColor: [
              ...props.reportTeamColors.slice(0, answers.length),
            ],
          },
        ],

        labels: answers,
      };
    }
  );

  const donutGraphs = donutData.map((data, i) => {
    return (
      <Col
        key={i}
        md={6}
        className="xl-mb-4 mb-2"
        style={{ minHeight: '250px' }}
      >
        <h4>
          {
            groupedConversationAnswers[
              Object.keys(groupedConversationAnswers)[i]
            ][0].question
          }
        </h4>
        <Doughnut height={80} data={data} options={options} />
        {tableData[i]}
      </Col>
    );
  });

  const options = {
    width: 200,
    height: 200,
  };

  return (
    <React.Fragment>
      <Row className="mb-4 pb-3">
        {props.conversationAnswers.length > 0 ? (
          donutGraphs
        ) : (
          <h4>
            {tFallback(
              'LABEL_DATA_NOT_YET_AVAILABLE',
              'This data is not yet available.'
            )}
          </h4>
        )}
      </Row>
    </React.Fragment>
  );
}

export default connect((state) => ({
  reportTeamColors: state.theme.reportTeamColors,
  allConversations: state.facilitatorState.allConversations,
  companies: state.facilitatorState.companies,
  conversationAnswers: state.facilitatorReports.conversationAnswers,
  filteredTeams: state.facilitatorReports.filteredTeams,
}))(ConversationResponses);
