import _ from 'lodash';
const employeeAvatarImages = require.context('./assets/img/avatars');

export function getBaseUrl() {
  const { host, protocol } = window.location;
  return `${protocol}//${host}`;
}

export function getBaseApiUrl() {
  // Note that this only replaces 3000 with 8080 for local dev
  return getBaseUrl().replace('3000', '8080') + '/api';
}

export function getBaseSocketUrl() {
  return getBaseUrl().replace('3000', '3001');
}

export function isFacilitatorReportsView() {
  return window.location.href.indexOf('facilitator/reports') > -1;
}

export function groupTimeAllocationsData(accumulator, token) {
  // TODO: I think this could be _.groupBy('type') and you count the array items.
  return _.some(accumulator, { type: token.type })
    ? accumulator.map((o) =>
        o.type === token.type ? _.assign({}, o, { count: o.count + 1 }) : o
      )
    : [...accumulator, _.assign({}, token, { count: 1 })];
}

export function getReportTokenType(token, tFallback) {
  if (token.tokenUse === 'Supported Project') {
    return tFallback('LABEL_CONTRIBUTED', 'Contributed');
  } else if (
    token.tokenUse.startsWith('Supported') &&
    token.tokenUse !== 'Supported Project'
  ) {
    return tFallback('LABEL_SUPPORT', 'Support');
  } else if (token.tokenUse.startsWith('Coached')) {
    return tFallback('LABEL_COACHING', 'Coaching');
  } else if (token.tokenUse.startsWith('Recognized')) {
    return tFallback('LABEL_RECOGNITION', 'Recognition');
  } else {
    console.error('Unhandled token: ', token);
  }
}

export function getReportTokenCategoryType(token, tFallback) {
  if (token.tokenUse.startsWith('Recognized')) {
    return token.recognitionType;
  } else if (
    token.tokenUse.startsWith('Coached') ||
    token.tokenUse === 'Supported Project'
  ) {
    const prefix = token.tokenUseCode.split('_')[0];
    return prefix === 'social'
      ? // toLowerCase because original logic before the 2023 translation project hard-coded lowercase
        tFallback('LABEL_COMMUNICATION', 'Communication').toLowerCase()
      : tFallback('LABEL_FUNCTIONAL', 'Functional').toLowerCase();
  }

  return '';
}

export function translateReportTitle(initialTitle, translateFunction) {
  switch (initialTitle) {
    case 'Time Allocation':
      return translateFunction('LABEL_TIME_ALLOCATION', 'Time Allocation');
    case 'Employee Details':
      return translateFunction('LABEL_EMPLOYEE_DETAILS', 'Employee Details');
    case 'Completed Tasks':
      return translateFunction('LABEL_COMPLETED_TASKS', 'Completed Tasks');
    case 'Market View':
      return translateFunction('LABEL_MARKET_VIEW', 'Market View');
    case 'Team Score Card':
      return translateFunction('LABEL_TEAM_SCORE_CARD', 'Team Score Card');
    case 'Engagement Growth':
      return translateFunction('LABEL_ENGAGEMENT_GROWTH', 'Engagement Growth');
    case 'Skill Growth':
      return translateFunction('LABEL_SKILL_GROWTH', 'Skill Growth');
    case 'Conversation Responses':
      return translateFunction(
        'LABEL_CONVERSATION_RESPONSES',
        'Conversation Responses'
      );
    default:
      return translateFunction('REPORTS', 'Reports');
  }
}

export const actionTypeMessageSubject = {
  coach: 'coach',
  recognize: 'recognize',
};

export function getEmployeeImageFile(imgFileName) {
  return employeeAvatarImages(`./${imgFileName}`);
}

// Written with ChatGPT's help to mimic how the app previously used lodash's _.startCase. Reason: lodash's startCase
// doesn't handle some Unicode characters like accents i.e. Portuguese 'Avaliação' would render as 'Avaliaco'
export function startCase(inputString) {
  // Inserting spaces before:
  // 1. A lowercase letter followed by an uppercase letter (e.g., "camelCase" -> "camel Case")
  // 2. An uppercase letter followed by a lowercase letter (e.g., "CamelCase" -> "Camel Case")
  // 3. A sequence of uppercase letters followed by a lowercase letter (e.g., "HTMLParser" -> "HTML Parser")
  inputString = inputString
    .replace(/([a-z\p{Ll}\d])([A-Z\p{Lu}])/gu, '$1 $2')
    .replace(/([A-Z\p{Lu}])([A-Z\p{Lu}][a-z\p{Ll}\d])/gu, '$1 $2')
    .replace(/([A-Z\p{Lu}][a-z\p{Ll}\d])([A-Z\p{Lu}])/gu, '$1 $2');

  // Splitting the input string into words
  // The regular expression here splits the string at non-letter characters while respecting unicode letters
  const words = inputString.match(/[\p{L}\d]+/gu) || [];

  // Capitalizing the first character of each word and joining the words
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
