import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { getSessionId, getPlayer } from './authService';
import store from '../redux/store/index';
import {
  SET_GAME_STATE,
  SET_REPORT_STATE,
  SET_CONVERSATION_STATE,
  START_TRAINING_EMPLOYEE,
  AUTHENTICATION_ERROR,
} from '../redux/redux-constants';
import ApiErrorMessage from '../components/ErrorMessage';

/* =========================== GETS */

export async function getGameState() {
  const payload = await callApi('/manager/getGameState', {});

  setState(SET_GAME_STATE, payload, 'game', getGameState);

  return payload;
}

export async function getReportState() {
  const payload = await callApi('/reports/getPlayerReportState', {});

  setState(SET_REPORT_STATE, payload, 'report', getReportState);
}

export async function getConversationState() {
  let payload = await callApi('/conversations/getConversationState', {});

  setState(
    SET_CONVERSATION_STATE,
    payload,
    'conversation',
    getConversationState
  );
}

export function getValidLoginCredentials(playerName) {
  return callApi('/manager/getValidLoginCredentials', { playerName });
}

function setState(actionType, payload, stateType, retryFn) {
  if (!_.isEmpty(payload)) {
    store.dispatch({
      type: actionType,
      payload,
      ...{},
    });
  } else {
    const errorMessage = `Error fetching ${stateType} state, will retry after timeout.`;
    toastr.light('API ERROR', {
      component: <ApiErrorMessage message={errorMessage} />,
      icon: 'error',
      status: 'error',
      timeOut: 15000,
      showCloseButton: true,
      removeOnHoverTimeOut: 0,
      onHideComplete: retryFn,
    });
  }
}

/* =========================== UPDATES */
export async function markChannelAsRead(channel) {
  callApi('/conversations/markChannelAsRead', { channel });
}

export function endPlayerConversation(messageId) {
  callApi('/conversations/endPlayerConversation', {
    messageId,
  });
}

export function selectMessageAnswer(messageId, answerId) {
  callApi('/conversations/selectMessageAnswer', {
    messageId,
    answerId,
  });
}

// ***** Employee updates *****
export async function recognizeEmployee(employeeId, recognitionType) {
  applyTokenToEmployee(employeeId, 'motivation', recognitionType);
}

export async function trainEmployeeFunctional(employeeId) {
  store.dispatch({
    type: START_TRAINING_EMPLOYEE,
    payload: {
      type: 'functional',
      employeeId,
    },
  });

  async function handleApplyToken() {
    await applyTokenToEmployee(employeeId, 'functional', '');
  }
  // Note: using timeout for spinner type use case. Can remove if we decide not to use that approach anymore.
  setTimeout(handleApplyToken, 3000);
}

export async function trainEmployeeCommunication(employeeId) {
  store.dispatch({
    type: START_TRAINING_EMPLOYEE,
    payload: {
      type: 'interpersonal', // TODO: use constants?
      employeeId,
    },
  });
  applyTokenToEmployee(employeeId, 'interpersonal', '');
}

async function applyTokenToEmployee(employeeId, statType, recognitionType) {
  callApi('/manager/applyTokenToEmployee', {
    employeeId,
    statType,
    recognitionType,
  });
}

// ***** Project Updates *****
export function assignEmployeeToProject(employeeId, projectId) {
  callApi('/manager/assignEmployeeToProject', { employeeId, projectId });
}

export function unassignEmployeeFromProject(employeeId, projectId) {
  callApi('/manager/unassignEmployeeFromProject', { employeeId, projectId });
}

export function activateProject(projectId) {
  callApi('/manager/activateProject', { projectId });
}

export function delegateProjectToEmployee(employeeId, projectId) {
  callApi('/manager/delegateProjectToEmployee', {
    projectId,
    employeeId,
  });
}

export function assignTokenToProject(projectId, skillType) {
  callApi('/manager/assignTokenToProject', {
    projectId,
    type: skillType,
  });
}

// TODO: global rename front/back of type --> skillType
export function unassignTokenTokenFromProject(tokenId) {
  callApi('/manager/unassignTokenFromProject', {
    tokenId,
  });
}

export function submitProject(projectId) {
  callApi('/manager/submitProject', {
    projectId,
  });
}

export function cancelProject(projectId) {
  callApi('/manager/deactivateProject', {
    projectId,
  });
}

export function supportEmployeeOnProject(employeeId, projectId) {
  callApi('/manager/assignTokenToSupportEmployee', {
    employeeId,
    projectId,
  });
}

export function getDictionary() {
  return callApi('/manager/getDictionary');
}

// ************ Helpers
function getIsTeammateView() {
  return (
    store &&
    store.getState() &&
    store.getState().player &&
    store.getState().player.isTeammateView
  );
}

function getCanManagersCollaborate() {
  return (
    store &&
    store.getState() &&
    store.getState().gameState &&
    store.getState().gameState.canManagersCollaborate
  );
}

function getTeammatePlayer() {
  return (
    store &&
    store.getState() &&
    store.getState().gameState &&
    store.getState().gameState.teammatePlayerName
  );
}

export async function callApi(endpoint, parameters) {
  const isPlayingOtherTeammate =
    getCanManagersCollaborate() && getIsTeammateView();

  const isGetGameState = endpoint === '/manager/getGameState';

  const sessionId = getSessionId();

  const playerName =
    isPlayingOtherTeammate && !isGetGameState
      ? getTeammatePlayer()
      : getPlayer();

  const options = {
    sessionId,
    playerName,
    ...parameters,
  };

  const requestUrl = getBaseUrl() + endpoint + '?' + getUrlParameters(options);

  return await callApiEndpoint(requestUrl);
}

async function callApiEndpoint(requestUrl) {
  try {
    const result = await axios(requestUrl, {
      headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
    });

    if (result.data && result.data.authenticationError) {
      store.dispatch({
        type: AUTHENTICATION_ERROR,
      });
      return;
    }

    return result.data;
  } catch (error) {
    const errorMessage = error || 'General API error, is app connected?';
    console.error(
      'callApiEndpoint request:',
      requestUrl,
      'error:',
      errorMessage
    );
    toastr.error('API FAILURE', errorMessage);
    return false;
  }
}

export function getBaseUrl() {
  const { host, protocol } = window.location;
  return `${protocol}//${host.replace('3000', '8080')}/api`;
}

function getUrlParameters(options) {
  return Object.keys(options)
    .map((key) => `${key}=${encodeURIComponent(options[key])}`)
    .join('&');
}
