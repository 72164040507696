import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import {
  player as playerRoutes,
  facilitator as facilitatorRoutes,
} from './index';

import PlayerLayout from '../layouts/Player';
import FacilitatorLayout from '../layouts/Facilitator';
import AuthLayout from '../layouts/Auth';

import ScrollToTop from '../components/ScrollToTop';
import SignIn from '../pages/auth/SignIn';

import Roster from '../pages/roster/Roster';

import {
  isLoggedIn,
  handleSignIn,
  handleSignOut,
} from '../services/authService';

const Routes = props => (
  <Router>
    <ScrollToTop>
      <Switch>
        {/* Facilitator routes */}
        <Route path="/admin" exact render={() => <Redirect to="/facilitator" />} />
        <Route
          path="/facilitator*"
          component={props => {
            return (
              <ChildRoutes
                exact
                layout={FacilitatorLayout}
                routes={facilitatorRoutes}
              />
            );
          }}
        />
        <Route
          path="/sign-in"
          render={props => {
            handleSignOut();
            return (
              <AuthLayout>
                <SignIn
                  handleSignIn={(playerName) =>
                    handleSignIn(playerName, props.history)
                  }
                />
              </AuthLayout>
            );
          }}
        />
        {/* VR route */}
        <Route exact path="/roster" render={() => <Roster />} />

        {/* Dashboard routes */}
        <Route path="/" exact render={() => <Redirect to="/dashboard" />} />
        <Route
          path="/*"
          exact
          render={() => {
            if (!isLoggedIn()) {
              return <Redirect to="/sign-in" />;
            }

            return <ChildRoutes layout={PlayerLayout} routes={playerRoutes} />;
          }}
        />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Routes;

const ChildRoutes = ({ layout: Layout, routes }) => (
  <Layout>
    <Switch>
      {routes.map((category, index) =>
        category.children ? (
          <Route
            key={category.path}
            path={category.path}
            render={() => {
              const ParentComponent = category.component;
              return (
                <ParentComponent>
                  {category.children.map((childRoute, index) => (
                    <Route
                      key={childRoute.path}
                      path={childRoute.path}
                      exact
                      component={childRoute.component}
                    />
                  ))}
                </ParentComponent>
              );
            }}
          />
        ) : (
          <Route
            key={category.path}
            path={category.path}
            exact
            component={category.component}
          />
        )
      )}
    </Switch>
  </Layout>
);
