import React from 'react';

import { Collapse, Navbar } from 'reactstrap';

export default ({ onToggleSidebar, children, color }) => {
  return (
    <Navbar color={color} light expand>
      <span
        className="sidebar-toggle d-flex mr-2 text-white"
        onClick={onToggleSidebar}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Collapse navbar>{children}</Collapse>
    </Navbar>
  );
};
