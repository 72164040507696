import React, { createContext, useContext, useState, useEffect } from 'react';
import { getDictionary } from '../services/apiService';

// Create the context
const DictionaryContext = createContext();

// Provider component
export const DictionaryProvider = ({ children }) => {
  const [dictionary, setDictionary] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Optional: Handle error state

  useEffect(() => {
    let isMounted = true; // To prevent setting state on unmounted component

    async function loadDictionary() {
      setLoading(true); // Set loading to true when the dictionary starts loading

      try {
        const timeoutId = setTimeout(() => {
          if (isMounted) {
            setLoading(false); // Fail after 15 seconds
            setError('Loading timeout');
          }
        }, 15000); // 15 seconds timeout

        const res = await getDictionary();
        if (isMounted) {
          setDictionary(res.dictionary);
          setLoading(false); // Set loading to false when the dictionary is loaded
          clearTimeout(timeoutId); // Clear timeout if dictionary loads in time
        }
      } catch (err) {
        if (isMounted) {
          setLoading(false);
          setError('Failed to load dictionary');
        }
      }
    }

    loadDictionary();

    // Cleanup function to avoid memory leaks if the component unmounts before timeout or API response
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <DictionaryContext.Provider value={{ dictionary, loading, error }}>
      {children}
    </DictionaryContext.Provider>
  );
};

// Hook to use the dictionary in components
export const useDictionary = () => {
  const context = useContext(DictionaryContext);
  if (!context) {
    throw new Error('useDictionary must be used within a DictionaryProvider');
  }
  return context;
};
