import {callApi, getValidLoginCredentials} from './apiService';

export function getPlayer() {
  return localStorage.getItem('playerName');
}

export function getSessionId() {
  const sessionToUse = localStorage.getItem('session');

  return sessionToUse ? sessionToUse : 'main';
}

export function getAuthDetails() {
  return {
    session: getSessionId(),
    playerName: getPlayer(),
  };
}

export function setAuthDetails(sessionName, playerName) {
  setSession(sessionName);
  setPlayerName(playerName);
}

function setPlayerName(playerName) {
  localStorage.setItem('playerName', playerName);
}

export function setSession(sessionId) {
  localStorage.setItem('session', sessionId);
}

export function clearSession() {
  localStorage.removeItem('session');
}

export function clearPlayerName() {
  localStorage.removeItem('playerName');
}

export function isLoggedIn() {
  return getPlayer() && getSessionId();
}

export function getAIAuthToken({claims}) {
  return callApi('/AI/auth', {claims});
}

export async function handleSignIn(playerNameInput, history) {
  const {
    sessionId,
    playerName, // Use the returned playerName to make casing match the db.
  } = await getValidLoginCredentials(playerNameInput);

  if (!sessionId) {
    return 'Unknown player name.';
  }

  setAuthDetails(sessionId, playerName);

  history.push('/dashboard');
  window.location.reload();
}

export function handleSignOut() {
  if (getSessionId()) {
    localStorage.setItem('lastSession', getSessionId());
  } else {
    localStorage.removeItem('lastSession');
  }
  // Leave session so we can autocomplete input
  clearPlayerName();
  clearSession();
}
