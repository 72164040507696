import * as types from '../../redux-constants';
import _ from 'lodash';

// TODO: confirm if any below is for player and breakout

import { milestones } from '../../../data-mocks';

const initialState = {
  selectedVideo: 'neutral_state',
  conversationVideoId: 'video1',
  previousVideoElemId: '',
  currentMilestone: milestones[0].id,
  milestoneTitle: milestones[0].title,
  milestoneDescription: milestones[0].description,
  showMilestoneDescription: true,
  conversationClipIsPlaying: false,
  points: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_VIDEO:
      return {
        ...state,
        selectedVideo: action.payload,
        conversationVideoId:
          action.payload === 'neutral_state.mp4'
            ? state.conversationVideoId
            : state.conversationVideoId === 'video1'
            ? 'video2'
            : 'video1',
        showMilestoneDescription: false,
        conversationClipIsPlaying: action.payload !== 'neutral_state.mp4',
      };

    case types.SET_CURRENT_MILESTONE:
      return {
        ...state,
        currentMilestone: action.payload,
        // TODO: better to just provide on the payload?
        milestoneTitle: _.find(milestones, { id: action.payload }).title,
        milestoneDescription: _.find(milestones, { id: action.payload })
          .description,
        showMilestoneDescription: true,
      };

    case types.SET_CLIP_ENDED:
      return {
        ...state,
        conversationClipIsPlaying: false,
      };

    case types.SET_VIDEO_POINTS:
      return {
        ...state,
        points: action.payload,
      };
    default:
      return state;
  }
}
