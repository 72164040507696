import { useDictionary } from '../context/dictionary-context';

export default function useConfig() {
  const { dictionary } = useDictionary();

  // This param is passed through a backtick (template literal)
  function t(keys) {
    const key = keys[0];
    const dictionaryEntry = dictionary?.find((entry) => entry.dkey === key);

    if (!dictionaryEntry) {
      if (dictionary && dictionary.length > 0) {
        console.error('Missing dictionary entry for key: ' + key);
      }
      return key;
    }
    return dictionaryEntry.translation;
  }

  function tFallback(key, fallback) {
    const dictionaryEntry = dictionary?.find((entry) => entry.dkey === key);

    if (!dictionaryEntry) {
      return fallback;
    }
    return dictionaryEntry.translation;
  }

  return { t, tFallback };
}
