import React from 'react';
import { Button } from 'reactstrap';
import useConfig from '../hooks/use-config';

const ApiErrorMessage = (props) => {
  const { tFallback } = useConfig();
  const handleLogout = () => {
    window.location.replace('/sign-in');
  };

  return (
    <div className="d-flex flex-column">
      {props.message}
      <Button block outline onClick={handleLogout} className="my-3">
        {tFallback('LABEL_SIGN_OUT', 'Sign Out')}
      </Button>
    </div>
  );
};

export default ApiErrorMessage;
