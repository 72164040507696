import * as types from '../../redux-constants';
import _ from 'lodash';

const initialState = {
  isLoaded: false,
  historicalCompanyScores: [],
  timeAllocations: [],
  projects: [],
  employees: [],
  filteredTeams: {},
  conversationAnswers: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_FACILITATOR_REPORT_STATE:
      return _.assign({}, action.payload, {
        isLoaded: true,
        filteredTeams: Object.keys(
          _.groupBy(action.payload.employees, 'companyName')
        ).reduce((acc, teamName) => {
          acc[teamName] = true;
          return acc;
        }, {}),
      });

    case types.SET_FACILITATOR_LATEST_REPORT_STATE: {
      const { thisMonthCompanyScores, thisMonthTimeAllocations, currentMonth } = action.payload;
      return _.assign({},
        state,
        _.omit(action.payload, ['thisMonthCompanyScores', 'thisMonthTimeAllocations', 'currentMonth']),
        {
          historicalCompanyScores: [
            ..._.reject(state.historicalCompanyScores, { month: currentMonth }),
            ...thisMonthCompanyScores
          ],
          timeAllocations: [
            ..._.reject(state.timeAllocations, { month: currentMonth }),
            ...thisMonthTimeAllocations
          ]
        }
      );
    }

    case types.SET_FACILITATOR_LATEST_DASHBOARD_REPORT_STATE: {
      const { thisMonthCompanyScores, thisMonthTimeAllocations, currentMonth } = action.payload;
      return _.assign({},
        state,
        {
          historicalCompanyScores: [
            ..._.reject(state.historicalCompanyScores, { month: currentMonth }),
            ...thisMonthCompanyScores
          ],
          timeAllocations: [
            ..._.reject(state.timeAllocations, { month: currentMonth }),
            ...thisMonthTimeAllocations
          ]
        }
      );
    }

    case types.UPDATE_FILTERED_TEAMS:
      return _.assign({}, state, { filteredTeams: action.payload });

    default:
      return state;
  }
}
