import sidebar from "./sidebarReducers";
import theme from "./themeReducer";
import conversation from "../facilitator/conversationsReducer";
import { reducer as toastr } from "react-redux-toastr";

export default {
  sidebar,
  theme,
  conversation,
  toastr
}