import {
  faGrinBeam,
  faSadCry,
  faAngry,
  faSurprise,
  faQuestionCircle,
  faSadTear,
  faMeh,
} from '@fortawesome/free-solid-svg-icons';

// Milestones
// Current Performance
// Taking credit
// Next Steps (training/feedback)
// Maybe: Future goals

// TODO: Ideally exit clips should know they are exits when scripted, or we need to concat a wrap up clip onto exit clips.
// TODO: consider moving from sub-category group labels back to titles if there are limited enough clips--although generic may want to stay broken out.
// TODO: manually sort based on usefullness.

// TODO: should Jim always start conversation in order to reset each milestone?

export const useVideoUrl = false;

const genericCategory = {
  category: 'Generic',
  items: [
    {
      id: 'quick1',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: 'Okay….',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick1.mp4',
      filename: 'quick1.mp4',
      color: 'warning',
      status: 'active',
    },
    {
      id: 'quick2',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: 'Okay!',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick2.mp4',
      filename: 'quick2.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'quick3',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: 'Sure',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick3.mp4',
      filename: 'quick3.mp4',
      color: 'info',
      status: 'active',
    },
    {
      id: 'quick4',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: 'Yes',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick4.mp4',
      filename: 'quick4.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'quick5',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: 'No',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick5.mp4',
      filename: 'quick5.mp4',
      color: 'danger',
      status: 'active',
    },
    {
      id: 'quick6',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: 'That sounds good',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick6.mp4',
      filename: 'quick6.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'quick7',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: 'Thanks',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick7.mp4',
      filename: 'quick7.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'quick8',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: "I don't think this is relevant.",
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick8.mp4',
      filename: 'quick8.mp4',
      color: 'info',
      status: 'active',
    },
    {
      id: 'quick9',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: "Yeah, that's a good idea.",
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick9.mp4',
      filename: 'quick9.mp4',
      color: 'warning',
      status: 'active',
    },
    {
      id: 'quick10',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: 'Cool.',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick10.mp4',
      filename: 'quick10.mp4',
      color: 'danger',
      status: 'active',
    },
    {
      id: 'quick12',
      subCategory: 'Quick/Terse',
      duration: 10,
      title: 'Quick Response',
      text: 'Not a problem!',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick12.mp4',
      filename: 'quick12.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'ambivalent',
      subCategory: 'Unsure/No Answer',
      duration: 10,
      title: 'Ambivalent ',
      text:
        "It could go either way. I'm not sure I have strong feelings or opinions on this.",
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/ambivalent.mp4',
      filename: 'ambivalent.mp4',
      color: 'warning',
      status: 'disabled',
    },
    {
      id: 'uncertain1',
      subCategory: 'Unsure/No Answer',
      duration: 10,
      title: 'Uncertain 1',
      text: 'I’m not entirely sure…',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/uncertain1.mp4',
      filename: 'uncertain1.mp4',
      color: 'warning',
      status: 'active',
    },
    {
      id: 'uncertain2',
      subCategory: 'Unsure/No Answer',
      duration: 10,
      title: 'Uncertain 2',
      text: "I'm not entirely sure how to answer that.",
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/uncertain2.mp4',
      filename: 'uncertain2.mp4',
      color: 'warning',
      status: 'active',
    },
    {
      id: 'uncertain3',
      subCategory: 'Unsure/No Answer',
      duration: 10,
      title: 'Uncertain 3',
      text:
        "I'm not sure have an answer for that right now. Perhaps I could think a little more and I could follow up with you later. ",
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/uncertain3.mp4',
      filename: 'uncertain3.mp4',
      color: 'warning',
      status: 'active',
    },
    {
      id: 'clarify1',
      subCategory: 'Ask for Clarification/Redirection',
      duration: 10,
      title: 'Clarify 1',
      text:
        'Sorry, I heard you ask a few things, could you give me those again, one at a time?',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/clarify1.mp4',
      filename: 'clarify1.mp4',
      color: 'warning',
      status: 'active',
    },
    {
      id: 'clarify2',
      subCategory: 'Ask for Clarification/Redirection',
      duration: 10,
      title: 'Clarify 2',
      text:
        'Could you clarify what you just said a bit for me? I want to make sure I understand.',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/clarify2.mp4',
      filename: 'clarify2.mp4',
      color: 'warning',
      status: 'active',
    },
    {
      id: 'redirect1',
      subCategory: 'Ask for Clarification/Redirection',
      duration: 10,
      title: 'Redirect 1',
      text: "Look,  I'm not feeling much like talking about this right now. ",
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/redirect1.mp4',
      filename: 'redirect1.mp4',
      color: 'warning',
      status: 'active',
    },
    {
      id: 'support2_p',
      subCategory: 'Humble Thanks',
      duration: 10,
      title: 'Support 2 (P)',
      text:
        'I know dealing with the new guy takes some patience, but I appreciate the effort.',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/support2_p.mp4',
      filename: 'support2_p.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'pleased',
      subCategory: 'General Pleased/Agreement',
      duration: 10,
      title: 'Pleased',
      text:
        "Thank you for your support and kind words. I'm really enjoying my work -- I feel like I am hitting my stride. ",
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/pleased.mp4',
      filename: 'pleased.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'pleased2',
      subCategory: 'General Pleased/Agreement',
      duration: 10,
      title: 'Pleased 2',
      text:
        "That’s good to hear. I'm happy I can be a contribution to the team. ",
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/pleased2.mp4',
      filename: 'pleased2.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'thankful2',
      subCategory: 'General Pleased/Agreement',
      duration: 10,
      title: 'Thankful 2',
      text: 'Thank you for the compliment, it means a lot coming from you.',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/thankful2.mp4',
      filename: 'thankful2.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'agreement',
      subCategory: 'General Pleased/Agreement',
      duration: 10,
      title: 'Agreement 1',
      text: "I don't have much to say other than I agree completely. ",
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/agreement.mp4',
      filename: 'agreement.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'agreement2',
      subCategory: 'General Pleased/Agreement',
      duration: 10,
      title: 'Agreement 2',
      text:
        'Sure, I understand what you mean. I really think that we are on the same page. ',
      url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/agreement2.mp4',
      filename: 'agreement2.mp4',
      color: 'success',
      status: 'active',
    },
    {
      id: 'general_question',
      subCategory: 'General Question',
      duration: 10,
      title: 'General Question',
      text: 'Can I ask a question of you?"',
      url:
        'https://d2ph12qijr8moi.cloudfront.net/conversations/general_question.mp4',
      filename: 'general_question.mp4',
      color: 'success',
      status: 'active',
    },
  ],
};

export const currentPerformance = [
  genericCategory,
  {
    category: 'Current Performance',
    items: [
      {
        id: 'quick11',
        subCategory: 'Launch Conversation Comment',
        duration: 10,
        title: 'Quick Response',
        text: 'Thanks for taking the time to meet. ',
        url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick11.mp4',
        filename: 'quick11.mp4',
        color: 'info',
        status: 'active',
      },
      {
        id: 'good_performance_p',
        subCategory: 'Performance Response',
        duration: 10,
        title: 'Good performance (P)',
        text:
          "I can talk about my performance... let's see... I think you'll see that I'm doing very well here. I don't know what that might mean down the road… but I'm glad to see that you're taking notice!",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/good_performance_p.mp4',
        filename: 'good_performance_p.mp4',
        color: 'success',
        status: 'disabled',
      },
      {
        id: 'wellbeing1',
        subCategory: 'Performance Response',
        duration: 10,
        title: 'Wellbeing ',
        text:
          "I'm doing well. It’s been busy, but productive. I've been really liking the more visible assignments so that's been fun.\"",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/wellbeing1.mp4',
        filename: 'wellbeing1.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'wellbeing2',
        subCategory: "How I'm Doing Response",
        duration: 10,
        title: 'Wellbeing 2',
        text:
          "I am doing pretty alright. I can't complain. Ramping up has been challenging but feeling good about it.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/wellbeing2.mp4',
        filename: 'wellbeing2.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'goals',
        subCategory: 'Goals',
        duration: 10,
        title: 'Goals',
        text:
          'My goals are to learn as much as possible and become an important and influential person in the company. I think I have a lot to offer. ',
        url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/goals.mp4',
        filename: 'goals.mp4',
        color: 'success',
        status: 'disabled',
      },
      {
        id: 'moving_up_p2',
        subCategory: 'Enjoyment About Work',
        duration: 10,
        title: 'Moving up 2 (P) ',
        text:
          'I really enjoy the strategic decisions that go into running a business. I think I have a lot to offer the company at that level, but I have to get there first.',
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/moving_up_p2.mp4',
        filename: 'moving_up_p2.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'whats_working_p',
        subCategory: 'Projects',
        duration: 10,
        title: "What's working (P)",
        text:
          "I've enjoyed working on projects that give me exposure to different roles in the company. The more varied the assignments and the people I interact with the better I can see where I want to be in the future. ",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/whats_working_p.mp4',
        filename: 'whats_working_p.mp4',
        color: 'success',
        status: 'disabled',
      },
      {
        id: 'whats_working_n',
        subCategory: 'Projects',
        duration: 10,
        title: "What's working (N) ",
        text:
          "I've enjoyed working on projects that give me exposure to the higher-ups. I'm just hoping to do more work like that. ",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/whats_working_n.mp4',
        filename: 'whats_working_n.mp4',
        color: 'warning',
        status: 'disabled',
      },
      {
        id: 'recent_project_positive',
        subCategory: 'Projects',
        duration: 10,
        title: 'Recent Project (P)',
        text:
          'I felt like things are going really well. The last project was a chance for me to show how valuable I could be. I think I did that.',
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/recent_project_positive.mp4',
        filename: 'recent_project_positive.mp4',
        color: 'success',
        status: 'disabled',
      },
      {
        id: 'personal_strengths1',
        subCategory: 'Personal Strengths',
        duration: 10,
        title: 'Personal Strengths 1',
        text:
          'I think I bring enthusiasm and afresh set of eyes to things. The advantage of being new is that I can see where we can go. My inexperience is really my biggest advantage if you think about it. ',
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/personal_strengths1.mp4',
        filename: 'personal_strengths1.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'personal_strengths2',
        subCategory: 'Personal Strengths',
        duration: 10,
        title: 'Personal Strengths 2',
        text:
          "I'm really smart and willing to put in the work. I know everyone on the team has their strong point, but I think I'm really the one giving it all I have for the project. ",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/personal_strengths2.mp4',
        filename: 'personal_strengths2.mp4',
        color: 'success',
        status: 'disabled',
      },
      {
        id: 'jim_according_to_others_p',
        subCategory: 'Describe Myself',
        duration: 10,
        title: 'Jim According to Others (P) ',
        text:
          "Others might think I'm wet behind the ears, but I have a lot to offer -- my youth and fresh perspective to name a few!",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/jim_according_to_others_p.mp4',
        filename: 'jim_according_to_others_p.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'jim_according_to_jim',
        subCategory: 'Describe Myself',
        duration: 10,
        title: 'Jim According to Jim',
        text:
          "I'm a laid back but driven individual. I like to think of myself as a innovator who is ready to make my mark on the world.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/jim_according_to_jim.mp4',
        filename: 'jim_according_to_jim.mp4',
        color: 'success',
        status: 'active',
      },
    ],
  },
  {
    category: 'Segue to Taking Credit (Exit)',
    items: [
      {
        id: 'change',
        points: 10,
        isExit: true,
        subCategory: 'How to Improve Performance',
        duration: 10,
        title: 'Change',
        text:
          'What can I be doing differently to achieve my goals at the company?',
        url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/change.mp4',
        filename: 'change.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'jim_according_to_others_n',
        isExit: true,
        subCategory: 'Acknowledge Lack of Team Feedback',
        duration: 10,
        title: 'Jim According to Others (N) ',
        text:
          "I truly don't know what others think of me here. I've been given no feedback so far.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/jim_according_to_others_n.mp4',
        filename: 'jim_according_to_others_n.mp4',
        color: 'warning',
        status: 'active',
      },
      {
        id: 'good_performance_n',
        isExit: true,
        subCategory: 'Respond to Comment/Question About Behavior',
        duration: 10,
        title: 'Good performance (N)',
        text:
          "Let's talk about my behavior... I have to admit, what you said makes me a bit nervous. I don't know what I might have done wrong. I've been trying really hard to show that I can be a valuable employee here",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/good_perforname_n.mp4',
        filename: 'good_perforname_n.mp4',
        color: 'warning',
        status: 'disabled',
      },
      {
        id: 'displeased',
        isExit: true,
        subCategory: 'Manager too Aggressive/Direct about Concerns',
        duration: 10,
        title: 'Displeased',
        text:
          "This is not going well, and I wasn't expecting this level of criticism. I'm pretty confused because I've been doing good work here and the team has told me so.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/displeased.mp4',
        filename: 'displeased.mp4',
        color: 'warning',
        status: 'active',
      },
      {
        id: 'moving_up_n2',
        subCategory: 'Manager too Aggressive/Direct about Concerns',
        duration: 10,
        title: 'Moving up 2 (N) ',
        text:
          "I'm supposedly a valuable contributor, but in the same breath I'm a problem?",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/moving_up_n2.mp4',
        filename: 'moving_up_n2.mp4',
        color: 'warning',
        status: 'active',
      },
      {
        id: 'redirect2',
        isExit: true,
        subCategory: 'Convo Lost Thread',
        duration: 10,
        title: 'Redirect 2',
        text:
          "Could we pivot a bit? I'd like to discuss more about how I can do more at the company.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/redirect2.mp4',
        filename: 'redirect2.mp4',
        color: 'warning',
        status: 'disabled',
      },
      {
        id: 'good_performance_n',
        isExit: true,
        subCategory: 'Behavior Response',
        duration: 10,
        title: 'Good performance (N)',
        text:
          "Let's talk about my behavior... I have to admit, what you said makes me a bit nervous. I don't know what I might have done wrong. I've been trying really hard to show that I can be a valuable employee here",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/good_perforname_n.mp4',
        filename: 'good_perforname_n.mp4',
        color: 'warning',
        status: 'disabled',
      },
    ],
  },
];

export const takingCredit = [
  genericCategory,
  {
    category: 'Taking Credit',
    items: [
      {
        id: 'redirect3',
        subCategory: 'Redirect back to Taking Credit',
        duration: 10,
        title: 'Redirect 3',
        text:
          "I'm feeling a little overwhelmed. Could we pause for a moment -- I mean what's really the issue? ",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/redirect3.mp4',
        filename: 'redirect3.mp4',
        color: 'warning',
        status: 'active',
      },
      {
        id: 'redirect4',
        subCategory: 'Redirect back to Taking Credit',
        duration: 10,
        title: 'Redirect 4',
        text:
          "Look, just slowing us down a bit here. What am I really doing that's so wrong? I just want to talk myself up a bit -- that's pretty normal, right?",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/redirect4.mp4',
        filename: 'redirect4.mp4',
        color: 'warning',
        status: 'active',
      },
      {
        id: 'moving_up_n1',
        isExit: true,
        subCategory: 'Redirect back to Taking Credit',
        duration: 10,
        title: 'Moving up 1 (N) ',
        text:
          "Wow. I don't really understand where this concern is coming from.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/moving_up_n1.mp4',
        filename: 'moving_up_n1.mp4',
        color: 'warning',
        status: 'active',
      },
      {
        id: 'displeased',
        subCategory: 'Disengaged/Displeased',
        duration: 10,
        title: 'Displeased',
        text:
          "This is not going well, and I wasn't expecting this level of criticism. I'm pretty confused because I've been doing good work here and the team has told me so.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/displeased.mp4',
        filename: 'displeased.mp4',
        color: 'warning',
        status: 'active',
      },
      {
        id: 'disengaged',
        subCategory: 'Disengaged/Displeased',
        duration: 10,
        title: 'Disengaged ',
        text:
          "With the way things are going I just don't feel like I'm plugged in here.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/disengaged.mp4',
        filename: 'disengaged.mp4',
        color: 'danger',
        status: 'active',
      },

      {
        id: 'recent_project_neutral',
        subCategory: 'Deny Problem',
        duration: 10,
        title: 'Recent Project (N)',
        text:
          "I think I'm doing really well. My last project had a good result. I really don't feel I did anything wrong.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/recent_project_neutral.mp4',
        filename: 'recent_project_neutral.mp4',
        color: 'warning',
        status: 'disabled',
      },

      {
        id: 'ineffective_direct_feedback_n1',
        subCategory: 'Blame Teammates',
        duration: 10,
        title: 'Ineffective, Direct Feedback 1 (N)',
        text:
          "Look, when I've had some success here, I've said something about it. Maybe if people are upset, they should stop being jealous and work harder on their own projects.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/ineffective_direct_feedback_n1.mp4',
        filename: 'ineffective_direct_feedback_n1.mp4',
        color: 'warning',
        status: 'active',
      },

      {
        id: 'jim_according_to_others_n',
        subCategory: 'What Others Think About Me',
        duration: 10,
        title: 'Jim According to Others (N) ',
        text:
          "I truly don't know what others think of me here. I've been given no feedback so far.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/jim_according_to_others_n.mp4',
        filename: 'jim_according_to_others_n.mp4',
        color: 'warning',
        status: 'active',
      },

      {
        id: 'effective_direct_feedback_p1',
        subCategory: 'Positive Response to Direct Feedback',
        duration: 10,
        title: 'Effective, Direct Feedback 1 (P)',
        text:
          "Ouch! I didn't know I was bugging people so much. I really didn't mean to do that. I wanted to be noticed, well liked. I wanted people to know what I was doing.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/effective_direct_feedback_p1.mp4',
        filename: 'effective_direct_feedback_p1.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'effective_direct_feedback_p2',
        subCategory: 'Positive Response to Direct Feedback',
        duration: 10,
        title: 'Effective, Direct Feedback 2 (P)',
        text:
          "You know, there are a lot of really talented people here. I look around and I see people who have been here for years, people who have skills and training that I don't have and may never have, people who don't even have to think about a lot of the things it still takes me time to figure out. I just want to be thought of well around here, that I'm someone worth having around.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/effective_direct_feedback_p2.mp4',
        filename: 'effective_direct_feedback_p2.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'effective_direct_feedback_p3',
        subCategory: 'Positive Response to Direct Feedback',
        duration: 10,
        title: 'Effective, Direct Feedback 3 (P)',
        text: 'What would make any of those people think twice about me?',
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/effective_direct_feedback_p3.mp4',
        filename: 'effective_direct_feedback_p3.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'effective_direct_feedback_p4',
        subCategory: 'Positive Response to Direct Feedback',
        duration: 10,
        title: 'Effective, Direct Feedback 4 (P)',
        text:
          "I just want to be thought of well around here, that I'm someone worth having around.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/effective_direct_feedback_p4.mp4',
        filename: 'effective_direct_feedback_p4.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'ineffective_direct_feedback_n2',
        subCategory: 'Negative Response to Direct Feedback',
        duration: 10,
        title: "I'm not trying to get under anyone's skin.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/ineffective_direct_feedback_n2.mp4',
        filename: 'ineffective_direct_feedback_n2.mp4',
        color: 'warning',
        status: 'active',
      },
      {
        id: 'ineffective_direct_feedback_n3',
        subCategory: 'Negative Response to Direct Feedback',
        duration: 10,
        title: 'Ineffective, Direct Feedback 3 (N)',
        text:
          'Maybe if people are upset, they should stop being jealous and work harder on their own projects.',
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/ineffective_direct_feedback_n3.mp4',
        filename: 'ineffective_direct_feedback_n3.mp4',
        color: 'warning',
        status: 'active',
      },

      {
        id: 'jim_according_to_others_p',
        subCategory: 'Describe Myself',
        duration: 10,
        title: 'Jim According to Others (P) ',
        text:
          "Others might think I'm wet behind the ears, but I have a lot to offer -- my youth and fresh perspective to name a few!",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/jim_according_to_others_p.mp4',
        filename: 'jim_according_to_others_p.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'jim_according_to_jim',
        subCategory: 'Describe Myself',
        duration: 10,
        title: 'Jim According to Jim',
        text:
          "I'm a laid back but driven individual. I like to think of myself as a innovator who is ready to make my mark on the world.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/jim_according_to_jim.mp4',
        filename: 'jim_according_to_jim.mp4',
        color: 'success',
        status: 'active',
      },
    ],
  },
  {
    category: 'Segue to Next Steps (Exit)',
    items: [
      {
        id: 'making_it_right',
        isExit: true,
        points: -20,
        subCategory: 'Making it Right / Changing',
        duration: 10,
        title: 'Making It Right',
        text: '"Do you feel I can salvage things with the team?"',
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/making_it_right.mp4',
        filename: 'making_it_right.mp4',
        color: 'success',
        status: 'active',
      },
    ],
  },
];

export const nextSteps = [
  genericCategory,
  {
    category: 'Next Steps',
    items: [
      {
        id: 'next_steps_n1',
        subCategory: 'Next Steps',
        duration: 10,
        title: 'Next Steps 1 (N)',
        text:
          'What kind of plan could we make together to make sure I can really excel here?”',
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/next_steps_n1.mp4',
        filename: 'next_steps_n1.mp4',
        color: 'warning',
        status: 'active',
      },
      {
        id: 'training_p',
        subCategory: 'Training',
        duration: 10,
        title: 'Training (P) ',
        text:
          'I appreciate professional training or mentorship. I know we have a lot of knowledgeable people on the team and the more I can learn from them the better for my progress at the company. ',
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/training_p.mp4',
        filename: 'training_p.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'training_n',
        subCategory: 'Training',
        duration: 10,
        title: 'Training (N)  ',
        text:
          "As far as development or training...I could meet with some people if you think it's valuable. Hopefully some important people who can show me to navigate...all of these concerns. ",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/training_n.mp4',
        filename: 'training_n.mp4',
        color: 'warning',
        status: 'active',
      },
      {
        id: 'feedback1',
        subCategory: 'Feedback',
        duration: 10,
        title: 'Feedback 1',
        text: 'Feedback is good. Helps me get to where I want to go faster. ',
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/feedback1.mp4',
        filename: 'feedback1.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'feedback2',
        subCategory: 'Feedback',
        duration: 10,
        title: 'Feedback 2',
        text:
          "I prefer my feedback in person. Any time I can have a face-to-face I'd love to do that.",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/feedback2.mp4',
        filename: 'feedback2.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'feedback3',
        subCategory: 'Feedback',
        duration: 10,
        title: 'Feedback 3',
        text:
          "I think we'll get to know each other better -- which helps if someone asks after me or my work...",
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/feedback3.mp4',
        filename: 'feedback3.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'advice3',
        subCategory: 'Opening Question',
        duration: 10,
        title: 'Advice 3 ',
        text:
          "“Thanks for the insight. Is there anything else I can be doing? I want to be sure I'm putting my best foot forward.”",
        url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/advice3.mp4',
        filename: 'advice3.mp4',
        color: 'success',
        status: 'active',
      },
      {
        id: 'support1_p',
        subCategory: 'Support',
        duration: 10,
        title: 'Support 1 (P)',
        text:
          'I feel supported when I can ask questions and get genuine answers. It can learn fastest that way. ',
        url:
          'https://d2ph12qijr8moi.cloudfront.net/conversations/support1_p.mp4',
        filename: 'support1_p.mp4',
        color: 'success',
        status: 'active',
      },
    ],
  },
  {
    category: 'Wrap-Up (Exit)',
    items: [
      {
        id: 'advice2',
        subCategory: 'Keep Open Dialogue',
        duration: 10,
        title: 'Advice 2',
        text:
          "Yeah, that's good. I think the people around here are great and I want to be thought of well, too. I should trust that they'll share with me if I share with them.",
        url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/advice2.mp4',
        filename: 'advice2.mp4',
        color: 'success',
        status: 'active',
      },

      {
        id: 'thankful',
        subCategory: 'Thank for Time & Wrap-Up',
        duration: 10,
        title: 'Thankful 1',
        text:
          'Thanks for taking the time to meet with me, this is really important. ',
        url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/thankful.mp4',
        filename: 'thankful.mp4',
        color: 'success',
        status: 'active',
      },
    ],
  },
];

export const milestones = [
  {
    id: 'currentPerformance',
    title: 'Getting Started',
    description:
      'Team members have grumbled about Jim taking credit for their work.\n\nUltimately, you want to address this concern with Jim but without him feeling ambushed.' +
      ' For now, ask Jim how feels about his current work performance, goals and/or recent projects.\n\nYour goal' +
      ' is to guide him towards how he could be doing better as a launchpad for discussing team members concerns.',
    videos: currentPerformance,
  },
  {
    id: 'takingCredit',
    title: 'Taking Credit',
    description:
      "It's time to talk about the issue.\n\nShare with Jim about teammate's concern that he is taking credit for their work.\n\nFind out how if he is aware of the issue," +
      ' how he feels about working with the team, and hopefully lead him towards a new phase of the conversation about how he could do better.',
    videos: takingCredit,
  },
  {
    id: 'nextSteps',
    title: 'Next Steps',
    description:
      'Now that you are hopefully both on the same page, talk with Jim about next steps.\n\nPerhaps training or new feedback channels could' +
      ' help prevent any future issues and improve his role as a dedicated team member.',
    videos: nextSteps,
  },
];

export const emotes = [
  {
    id: 'confident',
    label: 'Confident',
    url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/confident.mp4',
    filename: 'confident.mp4',
    icon: faGrinBeam,
  },
  {
    id: 'surprised',
    label: 'Surprised',
    url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/surprised.mp4',
    filename: 'surprised.mp4',
    icon: faSurprise,
  },
  {
    id: 'neutral_state',
    label: 'Neutral',
    url:
      'https://d2ph12qijr8moi.cloudfront.net/conversations/neutral_state.mp4',
    filename: 'neutral_state.mp4',
    icon: faMeh,
  },
  {
    id: 'confused',
    label: 'Confused',
    url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/confused.mp4',
    filename: 'confused.mp4',
    icon: faQuestionCircle,
  },
  {
    id: 'dejected',
    label: 'Dejected',
    url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/dejected.mp4',
    filename: 'dejected.mp4',
    icon: faSadTear,
  },
  {
    id: 'disappointed',
    label: 'Disappointed',
    url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/disappointed.mp4',
    filename: 'disappointed.mp4',
    icon: faSadCry,
  },
  {
    id: 'perturbed',
    label: 'Perturbed',
    url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/perturbed.mp4',
    filename: 'perturbed.mp4',
    icon: faAngry,
  },
];

export const conversations = [
  { value: 'jim', label: 'Jim: Disruptive Behavior' },
  { value: 'tamika', label: 'Tamika: New Something Thing' },
  { value: 'patrick', label: 'Patrick: Old something thing' },
];

export const teams = [
  { value: 'team1', label: 'Team 1' },
  { value: 'team2', label: 'Team 2' },
  { value: 'team3', label: 'Team 3' },
];

// const oldVideos = [
//   {
//     category: 'Generic',
//     items: [
//       { id: 'pleased',  subCategory: 'General Pleased/Agreement', duration: 10, title: 'Pleased', text: 'Thank you for your support and kind words. I\'m really enjoying my work -- I feel like I am hitting my stride. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/pleased.mp4', filename: 'pleased.mp4', color: 'success', status: 'active'},
//       { id: 'pleased2',  subCategory: 'General Pleased/Agreement', duration: 10, title: 'Pleased 2', text: 'That’s good to hear. I\'m happy I can be a contribution to the team. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/pleased2.mp4', filename: 'pleased2.mp4', color: 'success', status: 'active'},
//       { id: 'thankful2',  subCategory: 'General Pleased/Agreement', duration: 10, title: 'Thankful 2', text: 'Thank you for the compliment, it means a lot coming from you.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/thankful2.mp4', filename: 'thankful2.mp4', color: 'success', status: 'active'},
//       { id: 'agreement',  subCategory: 'General Pleased/Agreement', duration: 10, title: 'Agreement 1', text: 'I don\'t have much to say other than I agree completely. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/agreement.mp4', filename: 'agreement.mp4', color: 'success', status: 'active'},
//       { id: 'agreement2',  subCategory: 'General Pleased/Agreement', duration: 10, title: 'Agreement 2', text: 'Sure, I understand what you mean. I really think that we are on the same page. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/agreement2.mp4', filename: 'agreement2.mp4', color: 'success', status: 'active'},
//       { id: 'quick1',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'Okay….', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick1.mp4', filename: 'quick1.mp4', color: 'warning', status: 'active'},
//       { id: 'quick2',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'Okay!', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick2.mp4', filename: 'quick2.mp4', color: 'success', status: 'active'},
//       { id: 'quick3',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'Sure', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick3.mp4', filename: 'quick3.mp4', color: 'info', status: 'active'},
//       { id: 'quick4',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'Yes', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick4.mp4', filename: 'quick4.mp4', color: 'success', status: 'active'},
//       { id: 'quick5',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'No', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick5.mp4', filename: 'quick5.mp4', color: 'danger', status: 'active'},
//       { id: 'quick6',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'That sounds good', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick6.mp4', filename: 'quick6.mp4', color: 'success', status: 'active'},
//       { id: 'quick7',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'Thanks', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick7.mp4', filename: 'quick7.mp4', color: 'success', status: 'active'},
//       { id: 'quick8',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'I don\'t think this is relevant.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick8.mp4', filename: 'quick8.mp4', color: 'info', status: 'active'},
//       { id: 'quick9',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'Yeah, that\'s a good idea.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick9.mp4', filename: 'quick9.mp4', color: 'warning', status: 'active'},
//       { id: 'quick10',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'Cool.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick10.mp4', filename: 'quick10.mp4', color: 'danger', status: 'active'},
//       { id: 'quick11',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'Thanks for taking the time to meet. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick11.mp4', filename: 'quick11.mp4', color: 'info', status: 'active'},
//       { id: 'quick12',  subCategory: 'Quick/Terse', duration: 10, title: 'Quick Response', text: 'Not a problem!', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/quick12.mp4', filename: 'quick12.mp4', color: 'success', status: 'active'},
//       { id: 'ambivalent',  subCategory: 'Unsure/No Answer', duration: 10, title: 'Ambivalent ', text: 'It could go either way. I\'m not sure I have strong feelings or opinions on this.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/ambivalent.mp4', filename: 'ambivalent.mp4', color: 'warning', status: 'disabled'},
//       { id: 'uncertain1',  subCategory: 'Unsure/No Answer', duration: 10, title: 'Uncertain 1', text: 'I’m not entirely sure…', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/uncertain1.mp4', filename: 'uncertain1.mp4', color: 'warning', status: 'active'},
//       { id: 'uncertain2',  subCategory: 'Unsure/No Answer', duration: 10, title: 'Uncertain 2', text: 'I\'m not entirely sure how to answer that.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/uncertain2.mp4', filename: 'uncertain2.mp4', color: 'warning', status: 'active'},
//       { id: 'uncertain3',  subCategory: 'Unsure/No Answer', duration: 10, title: 'Uncertain 3', text: 'I\'m not sure have an answer for that right now. Perhaps I could think a little more and I could follow up with you later. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/uncertain3.mp4', filename: 'uncertain3.mp4', color: 'warning', status: 'active'},
//       { id: 'clarify1',  subCategory: 'Ask for Clarification/Redirection', duration: 10, title: 'Clarify 1', text: 'Sorry, I heard you ask a few things, could you give me those again, one at a time?', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/clarify1.mp4', filename: 'clarify1.mp4', color: 'warning', status: 'active'},
//       { id: 'clarify2',  subCategory: 'Ask for Clarification/Redirection', duration: 10, title: 'Clarify 2', text: 'Could you clarify what you just said a bit for me? I want to make sure I understand.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/clarify2.mp4', filename: 'clarify2.mp4', color: 'warning', status: 'active'},
//       { id: 'redirect1',  subCategory: 'Ask for Clarification/Redirection', duration: 10, title: 'Redirect 1', text: 'Look,  I\'m not feeling much like talking about this right now.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/redirect1.mp4', filename: 'redirect1.mp4', color: 'warning', status: 'active'},
//       { id: 'general_question',  subCategory: 'General Question', duration: 10, title: 'General Question', text: 'Can I ask a question of you?"', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/general_question.mp4', filename: 'general_question.mp4', color: 'success', status: 'active'}
//     ]
//   },
//   {
//     category: 'Work Performance',
//     items: [
//
//       { id: 'taking_credit_p',  subCategory: 'Taking Credit', duration: 10, title: 'Taking Credit (P)', text: 'I\'m really not trying to take credit from anyone. I was trying to… well, make people think I knew what I was doing. Not that I don\'t know what I\'m doing, I just- (sighs) because I\'m still relatively new, I worry if people will think I was worth hiring.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/taking_credit_p.mp4', filename: 'taking_credit_p.mp4', color: 'success', status: 'active'},
//       { id: 'taking_credit_n1',  subCategory: 'Taking Credit', duration: 10, title: 'Taking Credit (N)', text: 'I don\'t think talking about my own work, or my part in collaborative projects, means that I\'m trying to steal credit I don\'t deserve. I just want my work to be recognized by the people who matter here.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/taking_credit_n1.mp4', filename: 'taking_credit_n1.mp4', color: 'warning', status: 'active'},
//       { id: 'taking_credit_n2',  subCategory: 'Taking Credit', duration: 10, title: 'Taking Credit (N)', text: 'Sometimes we have collaborative projects here. People are pitching in to help others all the time. Maybe they\'re not comfortable talking about their accomplishments like I am.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/taking_credit_n2.mp4', filename: 'taking_credit_n2.mp4', color: 'warning', status: 'active'},
//       { id: 'team_dynamics_p',  subCategory: 'Team Dynamics', duration: 10, title: 'Team Dynamics (P)', text: 'I like working on the team because it\'s nice to learn from others who have experience. I don\'t know what I don\'t know -- it\'s been helping me get up to speed. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/team_dynamics_p.mp4', filename: 'team_dynamics_p.mp4', color: 'success', status: 'active'},
//       { id: 'team_dynamics_n',  subCategory: 'Team Dynamics', duration: 10, title: 'Team Dynamics (N)', text: 'Working on the team can be somewhat of a challenge. There are a lot of uh, personalities. But we get along fine as a team. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/team_dynamics_n.mp4', filename: 'team_dynamics_n.mp4', color: 'warning', status: 'active'},
//       { id: 'people_on_the_team_tamika',  subCategory: 'People on Team', duration: 10, title: 'People on the Team -Tamika ', text: 'Well, It\'s been great working with Tamika. She knows how to bring people together. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/people_on_the_team_tamika.mp4', filename: 'people_on_the_team_tamika.mp4', color: 'success', status: 'active'},
//       { id: 'people_on_the_team_janet',  subCategory: 'People on Team', duration: 10, title: 'People on the Team - Janet', text: 'Janet\'s quiet but she really gets things done. I envy her high-level of execution. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/people_on_the_team_janet.mp4', filename: 'people_on_the_team_janet.mp4', color: 'success', status: 'active'},
//       { id: 'people_on_the_team_patrick',  subCategory: 'People on Team', duration: 10, title: 'People on the Team - Patrick ', text: 'Patrick has been at the company longer than I have probably been alive. He\'s brusque, to say the least, but I hope to be as knowledgeable as him. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/people_on_the_team_patrick.mp4', filename: 'people_on_the_team_patrick.mp4', color: 'success', status: 'active'},
//       { id: 'admiration',  subCategory: 'Admiration/Respect/Pride', duration: 10, title: 'Admiration', text: 'I truly admire many people on the team. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/admiration.mp4', filename: 'admiration.mp4', color: 'success', status: 'active'},
//       { id: 'pride',  subCategory: 'Admiration/Respect/Pride', duration: 10, title: 'Pride ', text: 'I\'m proud the way I\'ve been able to jump right in with the more seasoned members of the team -- I feel I\'m keeping up really well. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/pride.mp4', filename: 'pride.mp4', color: 'success', status: 'active'},
//       { id: 'deserving1',  subCategory: 'Deserving', duration: 10, title: 'Deserving ', text: 'Everyone deserves to have their accomplishments recognized, absolutely. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/deserving1.mp4', filename: 'deserving1.mp4', color: 'success', status: 'active'},
//       { id: 'deserving2',  subCategory: 'Deserving', duration: 10, title: 'Deserving 2', text: 'I think I deserve to have my accomplishment recognized like everyone else. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/deserving2.mp4', filename: 'deserving2.mp4', color: 'success', status: 'active'},
//
//       // Exits
//       { id: 'apology_p',  subCategory: 'Apologize', duration: 10, title: 'Apology (P)', text: 'I can see how some of my colleagues might think that I was trying to take credit for their work. That wasn\'t my intention and I apologize for causing any problems.”', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/apology_p.mp4', filename: 'apology_p.mp4', color: 'success', status: 'active'},
//       { id: 'apology_n',  subCategory: 'Apologize', duration: 10, title: 'Apology (N)', text: 'I\'m sorry for any bad feelings I\'ve caused on team, but hopefully my performance will quiet some of the, uh...complaints. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/apology_n.mp4', filename: 'apology_n.mp4', color: 'warning', status: 'active'},
//
//       { id: 'respect',  subCategory: 'Admiration/Respect/Pride', duration: 10, title: 'Respect', text: 'I have a lot of respect for the people I work with. That might be why I\'ve been talking about my own accomplishments. I\'d like for them to think well of me.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/respect.mp4', filename: 'respect.mp4', color: 'success', status: 'active'},
//
//       // TODO: decide where these go
//       { id: 'advice1',  subCategory: 'Advice', duration: 10, title: 'Advice 1', text: '"Is there any other advice you can give me?"', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/advice1.mp4', filename: 'advice1.mp4', color: 'success', status: 'active'},
//       { id: 'advice4',  subCategory: 'Advice', duration: 10, title: 'Advice 4 ', text: '“You\'ve given me a lot to think about. Thanks for sharing with me.”', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/advice4.mp4', filename: 'advice4.mp4', color: 'success', status: 'active'}
//     ]
//   },
//   {
//     category: 'Personal',
//     items: [
//
//       { id: 'hobbies1',  subCategory: 'Hobbies', duration: 10, title: 'Hobbies 1', text: 'Later, I\'m probably hanging out with some college friends. It\'s important to keep in touch and what better way than a casual kicking back. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/hobbies1.mp4', filename: 'hobbies1.mp4', color: 'success', status: 'active'},
//       { id: 'hobbies2',  subCategory: 'Hobbies', duration: 10, title: 'Hobbies 2', text: 'There’s an alumni event at my alma mater, I might go to see who’s around and connect with some old colleagues. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/hobbies2.mp4', filename: 'hobbies2.mp4', color: 'success', status: 'active'},
//       { id: 'random1',  subCategory: 'Random', duration: 10, title: 'Random 1', text: 'That is a nice shirt you are wearing! Where did you get it?', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/random1.mp4', filename: 'random1.mp4', color: 'success', status: 'active'},
//       { id: 'random2',  subCategory: 'Random', duration: 10, title: 'Random 2', text: 'That is a nice outfit you are wearing! Where did you get it?', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/random2.mp4', filename: 'random2.mp4', color: 'success', status: 'active'}
//     ]
//   },
//   {
//     category: 'Future/Follow Up',
//     items: [
//
//       { id: 'feedback1',  subCategory: 'Feedback', duration: 10, title: 'Feedback 1', text: 'Feedback is good. Helps me get to where I want to go faster. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/feedback1.mp4', filename: 'feedback1.mp4', color: 'success', status: 'active'},
//       { id: 'feedback2',  subCategory: 'Feedback', duration: 10, title: 'Feedback 2', text: 'I prefer my feedback in person. Any time I can have a face-to-face I\'d love to do that.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/feedback2.mp4', filename: 'feedback2.mp4', color: 'success', status: 'active'},
//       { id: 'feedback3',  subCategory: 'Feedback', duration: 10, title: 'Feedback 3', text: 'I think we\'ll get to know each other better -- which helps if someone asks after me or my work...', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/feedback3.mp4', filename: 'feedback3.mp4', color: 'success', status: 'active'},
//       { id: 'support1_p',  subCategory: 'Support', duration: 10, title: 'Support 1 (P)', text: 'I feel supported when I can ask questions and get genuine answers. It can learn fastest that way. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/support1_p.mp4', filename: 'support1_p.mp4', color: 'success', status: 'active'},
//       { id: 'support2_p',  subCategory: 'Support', duration: 10, title: 'Support 2 (P)', text: 'I know dealing with the new guy takes some patience, but I appreciate the effort.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/support2_p.mp4', filename: 'support2_p.mp4', color: 'success', status: 'active'},
//       { id: 'support_n',  subCategory: 'Support', duration: 10, title: 'Support (N)', text: 'I would like to become visible to people in the organization. I know I have what it takes, I just don\'t want to be held back from the opportunity by others. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/support_n.mp4', filename: 'support_n.mp4', color: 'warning', status: 'active'},
//       { id: 'training_p',  subCategory: 'Training', duration: 10, title: 'Training (P) ', text: 'I appreciate professional training or mentorship. I know we have a lot of knowledgeable people on the team and the more I can learn from them the better for my progress at the company. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/training_p.mp4', filename: 'training_p.mp4', color: 'success', status: 'active'},
//       { id: 'training_n',  subCategory: 'Training', duration: 10, title: 'Training (N)  ', text: 'As far as development or training...I could meet with some people if you think it\'s valuable. Hopefully some important people who can show me to navigate...all of these concerns. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/training_n.mp4', filename: 'training_n.mp4', color: 'warning', status: 'active'},
//       { id: 'moving_up_p1',  subCategory: 'Ambition / Moving Up', duration: 10, title: 'Moving up 1 (P) ', text: 'I\'m still a young guy and I\'m at the bottom of the ladder at the company. I know that. Am I crazy for already thinking about where I want to end up in organization?  What sort of advice would you give to someone who wants to get on the management track?', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/moving_up_p1.mp4', filename: 'moving_up_p1.mp4', color: 'success', status: 'active'},
//       { id: 'moving_up_p2',  subCategory: 'Ambition / Moving Up', duration: 10, title: 'Moving up 2 (P) ', text: 'I really enjoy the strategic decisions that go into running a business. I think I have a lot to offer the company at that level, but I have to get there first.', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/moving_up_p2.mp4', filename: 'moving_up_p2.mp4', color: 'success', status: 'active'},
//       { id: 'moving_up_p3',  subCategory: 'Ambition / Moving Up', duration: 10, title: 'Moving up 3 (P) ', text: 'What sort of advice would you give to someone who wants to get on the management track?', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/moving_up_p3.mp4', filename: 'moving_up_p3.mp4', color: 'success', status: 'active'},
//       { id: 'moving_up_n2',  subCategory: 'Ambition / Moving Up', duration: 10, title: 'Moving up 2 (N) ', text: 'I\'m supposedly a valuable contributor, but in the same breath I\'m a problem?', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/moving_up_n2.mp4', filename: 'moving_up_n2.mp4', color: 'warning', status: 'active'},
//
//       { id: 'next_steps_p',  subCategory: 'Next Steps', duration: 10, title: 'Next Steps (P) ', text: 'I guess thinking about next steps, I\'d like to make sure we continue this open dialogue. Like you\'ve sort of said, if I\'m really interested in becoming a manager, I have to step up my game. What about a team lead position on some projects, in addition to some possible mentorship. ', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/next_steps_p.mp4', filename: 'next_steps_p.mp4', color: 'success', status: 'active'},
//
//       { id: 'next_steps_n1',  subCategory: 'Next Steps', duration: 10, title: 'Next Steps 1 (N)', text: 'What kind of plan could we make together to make sure I can really excel here?”', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/next_steps_n1.mp4', filename: 'next_steps_n1.mp4', color: 'warning', status: 'active'},
//
//       { id: 'next_steps_n2',  subCategory: 'Next Steps', duration: 10, title: 'Next Steps 2 (N)', text: 'I am not sure where to go from here. I will work more on being a team player I guess. Do you think this is going to affect my career here?', url: 'https://d2ph12qijr8moi.cloudfront.net/conversations/next_steps_n2.mp4', filename: 'next_steps_n2.mp4', color: 'warning', status: 'active'}
//     ]
//   }
//   // {
//   //   category: 'Questions',
//   //   items: [
//   //   ]
//   // },
// ];
