import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import useConfig from '../hooks/use-config';

const Footer = () => {
  const { tFallback } = useConfig();
  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs="6" className="text-left">
            <ul className="list-inline">
              <li className="list-inline-item border-right pr-3 mr-3">
                <a
                  href="https://portal.abilitie.com/help"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {tFallback('LABEL_SUPPORT', 'Support')}
                </a>
              </li>
              <li className="list-inline-item border-right pr-3 mr-3">
                <a
                  href="https://www.abilitie.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {tFallback('LABEL_PRIVACY', 'Privacy')}
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.abilitie.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {tFallback('LABEL_TERMS_OF_SERVICE', 'Terms of Service')}
                </a>
              </li>
            </ul>
          </Col>
          <Col xs="6" className="text-right">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} -{' '}
              <span className="text-muted">
                {tFallback('LABEL_ALL_RIGHTS_RESERVED', 'All Rights Reserved.')}{' '}
                <a
                  className="text-warning"
                  href="https://www.abilitie.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Abilitie
                </a>
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
